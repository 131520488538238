<template>
  <myHaed />
  <div class="home" v-show="true">
    <planNav />
    <div class="startlotto mybox">
      <!-- <p class="title">最新一期开奖</p> -->
      <div class="content">
        <startlottoNums />
      </div>
    </div>
    <plan />
    <daohang />
    <aomen_plan />
    <tukudaohang />
    <zhanwaishoulv />
    <!-- <xianggang_tuku /> -->
    <tuku />
    <!-- <img :src="gif4" class="banner" alt />
    <img :src="gif2" class="banner" alt />-->
    <!-- <img :src="gif3" class="banner" alt /> -->
    <!-- <img :src="gif4" class="banner" alt /> -->
    <xianggang_shoulu />
    <daohang1 />
    <!-- <img :src="gif5" class="banner" alt /> -->
    <!-- <img :src="gif6" class="banner" alt /> -->
    <aomen_shoulu />
    <typeTable />
  </div>
  <myFooter />
  <!-- <iframe
    style="width: 100%; height: 3000px"
    id="my-iframe"
    src="https://h4d-6x2.glcbookstore.com:2053/yjjy"
    frameborder="0"
  ></iframe>-->
</template>

<script setup>
import plan from '@/components/plan/plan.vue'
import planNav from '@/components/planNav.vue'
import daohang1 from '@/components/daohang1.vue'
import tukudaohang from '@/components/tukudaohang.vue'
import daohang from '@/components/daohang.vue'
import typeTable from "@/components/typeTable.vue";
import xianggang_shoulu from "@/components/xianggang_shoulu.vue";
import aomen_plan from "@/components/aomen_plan.vue";
import xianggang_tuku from "@/components/xianggang_tuku.vue";
import zhanwaishoulv from "@/components/zhanwaishoulv.vue";
import tuku from "@/components/tuku.vue";
import s1 from "@/assets/img/swipe/1.jpg";
import s2 from "@/assets/img/swipe/2.png";
import s3 from "@/assets/img/swipe/3.png";
import myHaed from "@/components/head.vue";
import startlottoNums from "@/components/startlottoNums.vue";
import aomen_shoulu from "@/components/aomen_shoulu.vue";
import myFooter from "@/components/myFooter.vue";
import { reactive, ref, nextTick } from "vue";

const gif2 = "https://www.7859888tp.com/750-50.gif";
const gif3 = "https://5598tp.com/tp/8tyaizopc.gif";
const gif4 = "https://5598tp.com/tp/8tyaizopc.gif";
const gif5 = "https://8932888tp.com/tp/btyd.gif";
const gif6 = "https://9323tp.cc/tp/960x60.gif";

const notice = ref(
  "无论我们能活多久，我们能够享受的只有无法分割的此刻，此外别无其他。"
);

//
function iframeLoad(iframeElement) {
  console.log("iframe加载完毕");
}
function handleIframe() {
  const iframe = document.getElementById("my-iframe");
  iframe.onload = iframeLoad(iframe);
}
nextTick(() => {
  // handleIframe();
});
</script>

<style lang="scss" scoped>
.floating-bubble-logo {
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 10px 0px #000;
}
.home {
  background-color: #fff;
}
::v-deep .van-notice-bar {
  height: 35px;
  line-height: 35px;
  div {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
  }
}
::v-deep .my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 100px;
  text-align: center;
  background-color: #39a9ed;
  .van-image__img {
    height: 100px;
    width: 100vw;
  }
}
.colorRed {
  color: red;
}
.startlotto {
  background-color: #fff;
  .title {
    font-weight: bold;
    text-align: center;
    background-color: #eee;
  }
}
</style>