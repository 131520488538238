<template>
  <ul>
    <p class="title">《优秀站外推广收率》</p>
    <li
      v-for="(item, index) in zhanwaishoulv"
      :key="index"
      @click="gourl(item)"
    >
      <img src="@/assets/img/gif-icon/5.gif" alt="" />
      <span>{{ item.name }}</span>
      <span style="color: red">【{{ item.plan }}】</span>
      <span>{{ item.url }}</span>
    </li>
  </ul>
  <ul>
    <p class="title">《澳门六合彩》</p>
    <li v-for="(item,index) in urlList2" :key="index">
      <img src="@/assets/img/liuheV3-imgs/1.gif" alt="" />
      <a :href="item.url" target="_blank">{{ item.name }}</a>
      <img src="@/assets/img/liuheV3-imgs/1.gif" alt="" />
    </li>
  </ul>
</template>

<script setup>
import { zhanwaishoulv, urlList2 } from "@/utils";
function gourl(e) {
  window.open("http://www." + e.url, "_blank");
}
</script>

<style lang="scss" scoped>
ul {
  border: 4px solid #004400;
  border-radius: 3px;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #004400;
    padding: 12px;
  }
  li {
    padding: 12px 0;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    background-color: #fff;
    &:not(:last-child) {
      border-bottom: 1px solid #3ea33e;
    }
    img {
      width: 15px;
      height: 15px;
      float: left;
      margin-left: 16px;
    }
  }
}
</style>