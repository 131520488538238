<template>
  <div class="plan_item">
    <div class="plan_item_advertisement">
      <!-- <img :src="banner" /> -->
      <!-- <img src="https://www.7859888tp.com/750-50.gif" /> -->
    </div>
    <p class="plan_item_title">
      <span>单双各四肖</span>
    </p>
    <ul>
      <li v-for="(el, i) in data.list" :key="i" :class="{ li_win: el.ifWin }">
        <span class="qi">{{ el.qiShu }}期【单双各四肖】</span>
        <span style="color:#1e19da">★单肖:</span>
        <span style="color:#9bc49c">||</span>
        <span
          class="code"
          v-for="(code, index) in el.yuCe.dan"
          :key="index"
          :class="{ win: el.shengXiao == code }"
          >{{ code }}</span
        >
        <span style="color:#9bc49c">||</span>
        <span style="color:#2e9a3c ">★双肖:</span>
        <span style="color:#9bc49c">||</span>
        <span
          class="code"
          v-for="(code, index) in el.yuCe.shuang"
          :key="index"
          :class="{ win: el.shengXiao == code }"
          >{{ code }}</span
        >
        <span style="color:#9bc49c">||</span>
        <span v-if="el.ifZuiXin">等开</span>
        <span v-else >
            开
            <span :style="{color:el.ifWin?'#338aff':''}">
            {{el.shengXiao}}{{ el.code }}
            {{ el.ifWin ? '准' : '错'}}
            </span>
        </span>
        <span></span>
      </li>
    </ul>
  </div>
</template>
      
      <script setup>
import top from "@/assets/img/top.gif";
import { toRefs } from "vue";
import { getNumSx } from "@/utils";

const props = defineProps({
  data: Object,
});

const { data } = toRefs(props);
data.value.list.forEach(item => {
    const {dan, shuang} = item.yuCe
    if(dan.includes(item.shengXiao) || shuang.includes(item.shengXiao)){
        item.ifWin = true
    }
});
</script>
      
<style lang="scss" scoped>
.plan_item {
  border: 1px solid #009788;
  .plan_item_advertisement {
    img {
      width: 100%;
      height: 40px;
      display: block;
    }
  }
  .plan_item_title {
    text-align: center;
    background-image: linear-gradient(to bottom, #787275, #edebec) !important;
    padding: 3px 0;
    border-top: 2px solid #767474;
    border-bottom: 2px solid #767474;
    img {
      display: inline-block;
      width: 30px;
      height: 30px;
    }
  }
  ul {
    background-color: #fff;
    li {
      border: none !important;
      text-align: center;
      padding:12px 5px !important;
      font-weight: bold;
      text-align: left;
      .code{
        color: red;
      }
      .win {
        background-color: #ffff00;
      }
      &:not(:last-child) {
        border-bottom: 2px solid #d2d2d2;
      }
    }
    .li_win {
      .winText {
        color: #309631;
      }
    }
  }
}
</style>