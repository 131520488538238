<template>
    <div class="plan_item">
      <div class="plan_item_advertisement">
        <!-- <img :src="banner" /> -->
        <!-- <img src="https://www.7859888tp.com/750-50.gif" /> -->
      </div>
      <p class="plan_item_title">
        <span>四肖八码</span>
        <img :src="top" alt="" />
      </p>
      <ul>
        <li v-for="(el, i) in data.list" :key="i" :class="{ li_win: el.ifWin }">
          <div class="qi">{{ el.qiShu }}期<span>四肖八码</span></div>
          <div class="vals">
           <span v-for="(item,index) in el.yuCe" :key="index">【
            <span :class="{win:el.shengXiao == item.sx}">{{ item.sx }}</span>:
            <span :class="{win:el.code == item.code1}">{{ item.code1 }}</span>.
            <span :class="{win:el.code == item.code2}">{{ item.code2 }}</span>
            】</span> 
          </div>
        </li>
      </ul>
    </div>
  </template>
      
      <script setup>
   
  import top from "@/assets/img/top.gif";
  import { toRefs } from "vue";
  import { getNumSx } from '@/utils'
  
  const props = defineProps({
    data: Object,
  });
  
  const { data } = toRefs(props);
  </script>
      
<style lang="scss" scoped>
  .plan_item {
    border: 1px solid #009788;
    .plan_item_advertisement {
      img {
        width: 100%;
        height: 40px;
        display: block;
      }
    }
    .plan_item_title {
      text-align: center;
      color: #fff;
      background-color: #fe2c1d;
      padding: 3px 0;
      border-top: 2px solid #767474;
      border-bottom: 2px solid #767474;
      img {
        display: inline-block;
        width: 30px;
        height: 30px;
      }
    }
    ul {
      background-color: #fff;
      li {
        text-align: center;
        padding:12px;
        font-weight: bold;
        .qi{
            span{
                color: #489347;
            }
        }
        .vals {
          color: red;
          display: grid;
          grid-gap: 0;
          grid-template-columns: repeat(2,1fr);
          .win {
            background-color: #ffff00;
          }
          >span{
            &:nth-child(odd){
                text-align: right;
            }
            &:nth-child(even){
                text-align: left;
            }
          }
        }
        &:not(:last-child) {
          border-bottom: 2px solid #d2d2d2;
        }
      }
      .li_win {
        .winText {
          color: #309631;
        }
      }
      .suck {
        text-decoration-line: line-through;
      }
    }
  }
  </style>