<template>
  <div class="daohang">
    <img src="@/assets/img/liuheV3-imgs/3.gif" alt="" />
    <div class="nav">
      <div
        class="item"
        :class="{ active: active == index }"
        v-for="(item, index) in nav"
        :key="index"
        @click="active = index"
      >
        {{ item }}
      </div>
    </div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <a :href="item.url" target="_blank">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { urlList4, urlList5, urlList6 } from "@/utils";
const active = ref(0);
const nav = ref(["澳门站点", "台湾站点", "香港站点"]);
const list = computed(() => {
  if (active.value == 0) {
    return urlList4;
  } else if (active.value == 1) {
    return urlList6;
  } else if (active.value == 2) {
    return urlList5;
  }
});
</script>

<style lang="scss" scoped>
.daohang {
    border: 3px solid #3ebc40;
  img {
    height: 50px;
    width: 100vw;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #3ebc40;
    .item {
      flex: 1;
      padding: 8px 0;
      text-align: center;
      color: #47a5ff;
      font-weight: bold;
      &:first-child {
        color: #ff59ff;
      }
      &:last-child {
        color: #ff4b4b;
      }
    }
    .active {
      background-color: #3ebc40;
      color: #fff !important;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    li {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 3px;
      background-color: #5a5a5a;
      a {
        font-size: 12px;
        color: #baba00;
        font-weight: bold;
      }
    }
  }
}
</style>