<template>
  <div class="daohang">
    <p class="title">港 / 澳 / 台 / 六合各大资料网</p>
    <div class="nav">
      <div
        class="item"
        :class="{ active: active == index }"
        v-for="(item, index) in nav"
        :key="index"
        @click="active = index"
      >
        {{ item }}
      </div>
    </div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <a :href="item.url" target="_blank">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>
  
  <script setup>
import { computed, ref } from "vue";
import { zongzhanxianggang, zongzhanliuhe, zongzhantaiwan } from "@/utils";
const active = ref(0);
const nav = ref(["香港六合", "六合彩", "台湾六合"]);
const list = computed(() => {
  if (active.value == 0) {
    return zongzhanxianggang;
  } else if (active.value == 1) {
    return zongzhanliuhe;
  } else if (active.value == 2) {
    return zongzhantaiwan;
  }
});
</script>
  
  <style lang="scss">
.daohang {
  border: 3px solid #3ebc40;
  .title{
    line-height: 50px;
    text-align: center;
    background-color: #0000ff;
    color: #fff;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #3ebc40;
    .item {
      flex: 1;
      padding: 8px 0;
      text-align: center;
      color: #47a5ff;
      font-weight: bold;
      &:first-child {
        color: #ff59ff;
      }
      &:last-child {
        color: #ff4b4b;
      }
    }
    .active {
      background-color: #3ebc40;
      color: #fff !important;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    li {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 12px 3px;
      background-color: #74d6e8;
      text-align: center;
      a {
        font-size: 12px;
        color: #000;
        font-weight: bold;
      }
    }
  }
}
</style>