<template>
  <div class="startlottoNum">
    <span class="text1" :style="{'background-image':`url(${getNumBg(num)})`}">{{ num }}</span>
    <span class="text2">{{getNumSx(num, qishu)}}/{{getNumWx(num)}}</span>
  </div>
</template>

<script setup>
import { toRefs } from "vue";
import { getNumSx, getNumBg, getNumWx } from "@/utils/index";

const props = defineProps({
  num: Number | String,
  qishu: Number | String,
});

const { num , qishu} = toRefs(props);
</script>

<style lang="scss" scoped>
.startlottoNum {
  background-color: #efefef;
  border: 2px solid #e0e0e0;
  border-radius: 3px;
  padding: 5px;
  span {
    font-size: 12px;
    display: block;
    text-align: center;
  }
  .text1 {
    background-size: 100% 100%;
    font-weight: bold;
    border-radius: 9999px;
    border: 3px solid transparent;
    padding: 5px;
    margin-bottom: 3px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, red, #32b81c);
  }
}
</style>