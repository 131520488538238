<template>
  <ul class="ul1">
    <li v-for="(item, index) in urlList3" :key="index">
      <img src="@/assets/img/liuheV3-imgs/2.gif" alt="" />
      <a :href="item.url" target="_blank">{{ item.name }}</a>
    </li>
  </ul>
  <ul class="mybox">
    <li v-for="(item, index) in list" :key="index">
      <img src="@/assets/img/gif-icon/5.gif" alt="" />
      <a :href="item.url" target="_blank">{{ item.title }}</a>
    </li>
  </ul>
</template>

<script setup>
import { drawHttp } from "@/request";
import { ref } from "vue";
import { urlList3 } from "@/utils";
const qi = ref("");
function loadData() {
  console.log("123456");
  drawHttp
    .get(`/gallerynew/h5/index/lastLotteryRecord/V2?lotteryType=2`)
    .then((result) => {
      qi.value = result.nextLotteryNumber;
    })
    .catch((err) => {
      console.log(err);
    });
}
loadData();
const list = [
  {
    title: "澳彩澳门汇〓三码连中19期〓推荐",
    url: "https://gxh608.malikasgames.com:2053/#4624914",
  },
  {
    title: "澳门猪八戒〖平特一肖〗主博六码! ",
    url: "https://www85749.zbjamce.com:8857/html/#98249",
  },
  {
    title: " 老奇人论坛→【欲钱四肖】已连中",
    url: "https://newstow.hcvhmd.com:12443/#46249",
  },
  {
    title: " 澳彩神算子【灭庄②肖】无敌准→ ",
    url: "https://ss60z5.websdenegocios.com:2053/#4624917",
  },
  {
    title: " 澳门四不像→六肖⑦码→黄金资料 ",
    url: "http://w422a27s.whxzzs.com:12443/#98249",
  },
];
</script>

<style lang="scss" scoped>
ul {
  li {
    border-bottom: 2px solid #eee;
    padding: 6px 12px;
    color: #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    a {
      color: #0000ff;
    }
  }
}
img {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
.ul1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  li {
    border: 1px solid #ccc;
    background-color: #fefeff;
  }
  a {
    color: #d56fd0;
    font-size: 12px;
    font-weight: bold;
  }
}
</style>