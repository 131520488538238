<template>
  <!-- <div class="head">
    <div class="left"></div>
    <div class="title">
      <img :src="logo" alt />
    </div>
    <div class="right">
      <img :src="typeTableIcon" alt @click="router.push({name:'typeTablePage'})" />
    </div>
  </div> -->
  <div class="head1">
    <div class="mask"></div>
    <img class="title" src="@/assets/img/liuheV3-imgs/header.png" />
    <a href="https://guhjgvf.www53923a.com/#223" target="_blank">
      <img class="img1" src="@/assets/img/liuheV3-imgs/6.gif" alt="" />
    </a>
  </div>
</template>

<script setup>
import typeTableIcon from "@/assets/img/typeTableIcon.png";
import logo from "@/assets/img/logo.png";
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 100px;
}
.img1 {
  width: 100%;
  height: 60px;
}
.head {
  width: 100vw;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 6px 10px;
  background-color: #fc9078;
  display: flex;
  flex: 1;
  justify-content: space-between;
  > div {
    width: 33.333%;
  }
  .title {
    text-align: center;
    img {
      height: 35px;
    }
  }
  .right {
    text-align: right;
    img {
      height: 35px;
      width: 65px;
    }
  }
}
.head1{
  position: relative;
  .mask{
    width: 230px;
    height: 30px;
    background-color: #d02928;
    position: absolute;
    top: 65px;
    left: 33px;
  }

}
</style>