<template>
    <div class="plan_item">
      <div class="plan_item_advertisement">
        <!-- <img :src="banner" /> -->
        <!-- <img src="https://www.7859888tp.com/750-50.gif" /> -->
      </div>
      <p class="plan_item_title">
        <span>家禽 + 野兽</span>
        <img :src="top" alt="" />
      </p>
      <ul>
        <li v-for="(el, i) in data.list" :key="i" :class="{ li_win: el.ifWin }">
          <span class="qi">{{ el.qiShu }}期</span>
          <span class="name">家禽野兽</span>
          <div :class="{ suck: !el.ifWin && !el.ifZuiXin }">
            <div class="value">
              【
              <span
                v-for="(num, numIndex) in el.ifZuiXin ? el.yuCe : el.shuXingList"
                :key="numIndex"
              >
                <span :class="{ win: num.ifWin }">{{ num.yuCe || num }}</span> 
                <span v-if="numIndex+2<el.yuCe.length">+</span> 
              </span>
              】
            </div>
          </div>
          <span class="winText" v-if="el.ifZuiXin">开？ 00准</span>
          <span class="winText" v-else>开：{{getNumSx(el.code, el.qiShu)}} {{el.code}}{{ el.ifWin ? "准" : "错" }}</span>
        </li>
      </ul>
    </div>
  </template>
      
      <script setup>
   
  import top from "@/assets/img/top.gif";
  import { toRefs } from "vue";
  import { getNumSx } from '@/utils'
  
  const props = defineProps({
    data: Object,
  });
  
  const { data } = toRefs(props);
  </script>
      
<style lang="scss" scoped>
  .plan_item {
    border: 1px solid #009788;
    .plan_item_advertisement {
      img {
        width: 100%;
        height: 40px;
        display: block;
      }
    }
    .plan_item_title {
      text-align: center;
      color: #fff;
      background: red;
      padding: 3px 0;
      border-top: 2px solid #767474;
      border-bottom: 2px solid #767474;
      img {
        display: inline-block;
        width: 30px;
        height: 30px;
      }
    }
    ul {
      background-color: #fff;
      li {
        text-align: center;
        padding:12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        .name{
            color: #6914e3;
        }
        .value {
          color: red;
          .win {
            background-color: #ffff00;
          }
        }
        &:not(:last-child) {
          border-bottom: 2px solid #d2d2d2;
        }
      }
      .li_win {
        .winText {
          color: #309631;
        }
      }
    }
  }
  </style>