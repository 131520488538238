export const fromList = new Map([
    ["01", { sx: '兔', wx: "金", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["02", { sx: '虎', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["03", { sx: '牛', wx: "土", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["04", { sx: '鼠', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["05", { sx: '猪', wx: "木", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["06", { sx: '狗', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["07", { sx: '鸡', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["08", { sx: '猴', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["09", { sx: '羊', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["10", { sx: '马', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["11", { sx: '蛇', wx: "水", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["12", { sx: '龙', wx: "水", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["13", { sx: '兔', wx: "木", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["14", { sx: '虎', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["15", { sx: '牛', wx: "火", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["16", { sx: '鼠', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["17", { sx: '猪', wx: "土", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["18", { sx: '狗', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["19", { sx: '鸡', wx: "水", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["20", { sx: '猴', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["21", { sx: '羊', wx: "木", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["22", { sx: '马', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["23", { sx: '蛇', wx: "金", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["24", { sx: '龙', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["25", { sx: '兔', wx: "土", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["26", { sx: '虎', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["27", { sx: '牛', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["28", { sx: '鼠', wx: "水", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["29", { sx: '猪', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["30", { sx: '狗', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["31", { sx: '鸡', wx: "金", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["32", { sx: '猴', wx: "金", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["33", { sx: '羊', wx: "土", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["34", { sx: '马', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["35", { sx: '蛇', wx: "木", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["36", { sx: '龙', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["37", { sx: '兔', wx: "火", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["38", { sx: '虎', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["39", { sx: '牛', wx: "金", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["40", { sx: '鼠', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["41", { sx: '猪', wx: "水", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["42", { sx: '狗', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["43", { sx: '鸡', wx: "木", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["44", { sx: '猴', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["45", { sx: '羊', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["46", { sx: '马', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["47", { sx: '蛇', wx: "土", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["48", { sx: '龙', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["49", { sx: '兔', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
])
export const fromList24 = new Map([
    ["01", { sx: '龙', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["02", { sx: '兔', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["03", { sx: '虎', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["04", { sx: '牛', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["05", { sx: '鼠', wx: "土", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["06", { sx: '猪', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["07", { sx: '狗', wx: "木", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["08", { sx: '鸡', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["09", { sx: '猴', wx: "火", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["10", { sx: '羊', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["11", { sx: '马', wx: "金", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["12", { sx: '蛇', wx: "水", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["13", { sx: '龙', wx: "水", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["14", { sx: '兔', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["15", { sx: '虎', wx: "木", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["16", { sx: '牛', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["17", { sx: '鼠', wx: "火", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["18", { sx: '猪', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["19", { sx: '狗', wx: "土", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["20", { sx: '鸡', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["21", { sx: '猴', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["22", { sx: '羊', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["23", { sx: '马', wx: "木", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["24", { sx: '蛇', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["25", { sx: '龙', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["26", { sx: '兔', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["27", { sx: '虎', wx: "土", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["28", { sx: '牛', wx: "水", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["29", { sx: '鼠', wx: "水", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["30", { sx: '猪', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["31", { sx: '狗', wx: "火", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["32", { sx: '鸡', wx: "金", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["33", { sx: '猴', wx: "金", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["34", { sx: '羊', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["35", { sx: '马', wx: "土", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["36", { sx: '蛇', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["37", { sx: '龙', wx: "木", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["38", { sx: '兔', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["39", { sx: '虎', wx: "火", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["40", { sx: '牛', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["41", { sx: '鼠', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["42", { sx: '猪', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["43", { sx: '狗', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["44", { sx: '鸡', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["45", { sx: '猴', wx: "木", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["46", { sx: '羊', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["47", { sx: '马', wx: "火", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["48", { sx: '蛇', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["49", { sx: '龙', wx: "土", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
])

export function getNumConfig(num, qishu = null) {
    if(qishu && qishu > 40){
        return fromList24.get(num)
    } else{
        return fromList.get(num)
    }
}

export function getNumSx(num, qishu = null) {
    return getNumConfig(num, qishu).sx
}
export function getNumWx(num, qishu = null) {
    return getNumConfig(num, qishu).wx
}
export function getNumBs(num) {
    return getNumConfig(num).bs
}
export function getNumColor(num) {
    return getNumConfig(num).color
}
export function getNumBg(num) {
    return getNumConfig(num).numBg.href
}

export function calculateTimeDifference(year, month, day, hour, minute, second) {
    var currentDateTime = new Date();
    var givenDateTime = new Date(year, month - 1, day, hour, minute, second);
    var timeDifference = currentDateTime - givenDateTime;

    var years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
    var months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30) % 12);
    var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) % 30;
    var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}

export const aomen_shoulu = [
    {
        title: '澳彩广东会【特码，平特，波色】全有',
        url: 'https://g529dh.loremagazine.com:2053/#am43229'
    },
    {
        title: '澳门慈善网【家禽野兽】计划平特肖',
        url: 'https://csw703.chouhanbusiness.com:2053/#43229'
    },
    {
        title: '澳门大赢家【必中⑥肖】▲爆特诗句',
        url: 'https://d8y6j9.milmares.com:2053/#43229'
    },
    {
        title: '澳门71049【精选三肖】平特一肖中',
        url: 'https://01wz7w.harryenglishclub.com:2053/#am43229'
    },
    {
        title: '澳门27549【期期四肖】内幕２０码',
        url: 'https://p5j-7g2.mysantosha.com:2053/#am43229'
    },
    {
        title: '澳门41649【平特一尾】最强猛料■',
        url: 'https://426esl.xumutoutiao.com:2053/#am43229'
    },
    {
        title: '澳门62449【神秘平特】⑤肖中特火',
        url: 'https://k62j4w.riverbarfarms.com:2053/#am43229'
    },
    {
        title: '澳门24149【四肖主八码】万众瞩目',
        url: 'https://pst241.askaroundme.com:2053/#am43229'
    },
    {
        title: '澳门中特网【公式规律码】连准多期',
        url: 'https://33zt2w.sovaparents.com:2053/#am43229'
    },
    {
        title: '澳门中特网【公式规律码】连准多期',
        url: 'https://33zt2w.sovaparents.com:2053/#am43229'
    },
    {
        title: '澳门48449【单双单双王】计划平特',
        url: 'https://z48d4r.freetechebooks.com:2053/#am43229'
    },
    {
        title: '澳门74249【正版挂牌】爆六码中特',
        url: 'https://j7s4p2.pacificcrestbuildersinc.com:2053/#am43229'
    },
    {
        title: '澳门62549【现场直播】看开奖记录',
        url: 'https://x62j5b.kudosclimbing.com:2053/#am43229'
    },
    {
        title: '澳门64149【五肖主五码】攻平特肖',
        url: 'https://lg64-z1.greenboxfilms.com:2053/#am43229'
    },
    {
        title: '澳门33649【必中⑥肖】看精彩图纸',
        url: 'https://l3b-w36.bisabikinrumah.com:2053/#am43229'
    },

]

export const xianggang_shoulu = [
    {
        title: '正版一句爆特',
        url: 'https://jdb-903.scotibankpr.com:2053/#43229'
    },
    {
        title: '灭庄一肖一码',
        url: 'https://p3j8b9.elieshanelson.com:2053/#4322901'
    },
    {
        title: '二肖主博四码',
        url: 'https://f42hw7.adoptunderstand.com:2053/#9824906'
    },
    {
        title: '三字爆解特码',
        url: 'https://01wz7w.harryenglishclub.com:2053/#4322907'
    },
    {
        title: '内部推荐②肖',
        url: 'https://dd087lt.shtbmc.com:2090/#98249'
    },
    {
        title: '重磅三肖三码',
        url: 'https://d4d7q8.mingmuzhijia.com:2053/#43229'
    },
    {
        title: '平特复式连肖',
        url: 'https://j9c3-t2.strengthpurchase.com:2053/#98249d7'
    },
    {
        title: '美女重砸两肖',
        url: 'https://m27dl0.premiosnutrisenior.com:2053/#4322908'
    },
    {
        title: '今日头条②粒',
        url: 'https://2am8fy7.dpsxeozuylychguv.com:2058/#46249'
    },
    {
        title: '主攻三码中特',
        url: 'https://m2z7g8.3getonline.com:2053/#9824910'
    },
    {
        title: '注意:②肖中特',
        url: 'https://ss60z5.websdenegocios.com:2053/#4624917'
    },
    {
        title: '欲钱来这里找',
        url: 'http://w422a27s.whxzzs.com:12443/#98249'
    },
]

export const xianggang_tuku = [
    {
        title: '香港挂牌',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10355&type=1',
        bg: '#ffff00'
    },
    {
        title: '另版跑狗',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15855&type=1'
    },
    {
        title: '老版跑狗',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=12715&type=1',
        bg: '#c3147e'
    },
    {
        title: '凌波微步',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15825&type=1'
    },
    {
        title: '踏雪无痕',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15865&type=1',
        bg: '#009900'
    },
    {
        title: '正版四不像',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=12679&type=1'
    },
    {
        title: '神童平特',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10859&type=1'
    },
    {
        title: '金多宝传真',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=13013&type=1'
    },
    {
        title: '马会传真',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=416980&type=1'
    },
    {
        title: '铁算盘',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10592&type=1'
    },
    {
        title: '马会生活幽默',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15823&type=1'
    },
    {
        title: '脑筋急转弯',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10562&type=1'
    },
    {
        title: '挂牌玄机',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10870&type=1'
    },
    {
        title: '挂牌天书',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10873&type=1'
    },
    {
        title: '广州传真中特',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10616&type=1'
    },
    {
        title: '美女六肖图',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10858&type=1',
        bg: '#00ff00'
    },
    {
        title: '频果报',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10589&type=1'
    },
    {
        title: '东成西就',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15961&type=1'
    },
    {
        title: '六合头条',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15831&type=1',
        bg: '#ff00ff'
    },
    {
        title: '八仙过海',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10667&type=1'
    },
    {
        title: '白小姐点金',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10247&type=1',
        bg: '#ff0000'
    },
]

export const zhanwaishoulv = [
    {
        name: '六合宝典',
        plan: '精选六肖',
        url: 'baiao.xyz',
    },
    {
        name: '玄机特码',
        plan: '惊爆一肖',
        url: '86281.com',
    },
    {
        name: '一哥神码',
        plan: '必中三肖',
        url: '82200.com',
    },
    {
        name: '金牌谜语',
        plan: '包中一肖',
        url: '21689.com',
    },
    {
        name: '澳门大神',
        plan: '三肖六码',
        url: '23612.com',
    },
    {
        name: '特码大神',
        plan: '一肖一码',
        url: '82200.com',
    },
    {
        name: '澳门马票',
        plan: '无错单双',
        url: '26312.com',
    },
    {
        name: '一头一波',
        plan: '爆庄一码',
        url: '21539.com',
    },
    {
        name: '趣味幽默',
        plan: '3.码3.码',
        url: '27521.com',
    },
    {
        name: '澳王中王',
        plan: '三期必中',
        url: '72385.com',
    },
    {
        name: '小精禁肖',
        plan: '精准平特',
        url: '26379.com',
    },
    {
        name: '葡京赌侠',
        plan: '一肖一码',
        url: '27931.com',
    },
    {
        name: '拐特大师',
        plan: '10组3中3',
        url: '28193.com',
    },
    {
        name: '字花报网',
        plan: '独平一码',
        url: '27291.com',
    },
    {
        name: '欲钱料网',
        plan: '二肖三码',
        url: '25791.com',
    },
    {
        name: '广州传真',
        plan: '一肖中特',
        url: '21591.com',
    },
    {
        name: '幽默玄机',
        plan: '四肖八码',
        url: '28193.com',
    },
    {
        name: '传真3肖版',
        plan: '二码中特',
        url: '39875.com',
    },
    {
        name: '踏雪无痕',
        plan: '牛逼一波',
        url: '72785.com',
    },
    {
        name: '凌波微步',
        plan: '一肖中特',
        url: '72897.com',
    },
    {
        name: '幽默另版',
        plan: '平特一码',
        url: '73159.com',
    },
    {
        name: '图找生肖',
        plan: '2肖中特',
        url: '39187.com',
    },
    {
        name: '幽默猜测',
        plan: '五码爆庄',
        url: '82159.com',
    },
    {
        name: '内幕传真',
        plan: '3码中特',
        url: '65292.com',
    },
    {
        name: '一句玄机',
        plan: '爆庄一肖',
        url: '28359.com',
    },
    {
        name: '生活幽默',
        plan: '1码中特',
        url: '72579.com',
    },
    {
        name: '蓝月亮网',
        plan: '平码2中2',
        url: '49171.com',
    },
    {
        name: '唐朝论坛',
        plan: '四肖中特',
        url: '38529.com',
    },
    {
        name: '港澳百家',
        plan: '单双100%',
        url: '71156.com',
    },
    {
        name: '港澳宝典',
        plan: '平特一肖',
        url: '71152.com',
    },
    {
        name: '通天论坛',
        plan: '内幕二肖',
        url: '59197.com',
    },
    {
        name: '另版传真',
        plan: '内部一肖',
        url: '86217.com',
    },
    {
        name: '顶级论坛',
        plan: '三肖中特',
        url: '51926.com',
    },
    {
        name: '码头诗网',
        plan: '一肖一码',
        url: '79539.com',
    }
]

export const urlList1 = [
    { name: '五色波玩', url: 'https://r53pc7.www63539a.com:8443/' },
    { name: '肖码精英', url: 'https://m1scyp.www56521a.com:8443/' },
    { name: '特码冠军', url: 'https://8ish7w.www52817a.com:8443/' },
    { name: '码中特规', url: 'https://fgy3zz.www53183a.com:8443/' },
    { name: '波色预测', url: 'https://8zj9xn.www79591a.com:8443/' },
    { name: '绝杀特码', url: 'https://g5hg3h.www27921a.com:8443/' },
    { name: '必中号码', url: 'https://972myn.www28291a.com:8443/' },
    { name: '暗码预测', url: 'https://3zqot8.www31976b.com:8443/' },
    { name: '五肖十码', url: 'https://fnvon7.www95287a.com:8443/' },
    { name: '特码高手', url: 'https://esjos1.www25261a.com:8443/' },
    { name: '特码精灵', url: 'https://r53pc7.www63539a.com:8443/' },
    { name: '波色王', url: 'https://8pej1i.www23615a.com:8443/' },
    { name: '肖码高手', url: 'https://wf6dph.www15637a.com:8443/' },
    { name: '肖码赔率', url: 'https://972myn.www28291a.com:8443/' },
    { name: '澳门来料', url: 'https://3khjtw.www52785a.com:8443/' },
    { name: "肖码秘诀", url: "https://1s6nhv.www28715a.com:8443" },
    { name: "码王预测", url: "https://b3sxtt.www21531a.com:8443" },
    { name: "特码秘密", url: "https://8zj9xn.www79591a.com:8443" },
    { name: "大小中特", url: "https://r53pc7.www63539a.com:8443" },
    { name: "波色赔率", url: "https://wf6dph.www15637a.com:8443" },
    { name: "特码大师", url: "https://972myn.www28291a.com:8443" },
    { name: "六合开奖", url: "https://51h9te.www31793a.com:8443" },
    { name: "六肖必中", url: "https://thmca8.www28793a.com:8443" },
    { name: "绝杀一肖", url: "https://8pej1i.www23615a.com:8443" },
    { name: "二肖中特", url: "https://8ish7w.www52817a.com:8443" },
    { name: "二肖输尽", url: "https://b3sxtt.www21531a.com:8443" },
    { name: "特码玄机", url: "https://8ish7w.www52817a.com:8443" },
    { name: "三肖中特", url: "https://8pej1i.www23615a.com:8443" },
    { name: "中奖秘诀", url: "https://8pej1i.www23615a.com:8443" },
    { name: "肖码精英", url: "https://b3sxtt.www21531a.com:8443" }
]

export const urlList2 = [   //澳门
    { name: "澳门澳利澳【24码中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/04.html" },
    { name: "澳门澳利澳【三肖中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/06.html" },
    { name: "澳门澳利澳【五肖中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/07.html" },
    { name: "澳门澳利澳【六肖中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/08.html" },
    { name: "澳门澳利澳【12码中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/09.html" },
    { name: "澳门澳利澳【平特一肖】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/10.html" },
    { name: "澳门澳利澳【18码中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/11.html" },
    { name: "澳门澳利澳【五尾中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/01.html" },
    { name: "澳门澳利澳【铁杀二肖】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/02.html" },
    { name: "澳门澳利澳【四肖中特】已公开！", url: "https://asxdddd.www69362b.com:888/kjb/03.html" }
]

export const urlList3 = [ //综合
    { name: "澳门六合宝典透蜜一码网✔", url: "https://19bjwc.www73125c.com:888/#123" },
    { name: "澳门王中王八肖连中25期✔", url: "https://dsjkjkjkj.www72385c.com:8443/#123" },
    { name: "澳门澳利澳网二肖五码连连中✔", url: "https://alaaaa.www68729a.com:888/#123" },
    { name: "30码中特网①高手资料已公开✔", url: "https://dsadas.www58652a.com:888/#123" },
    { name: "一句真言网①肖连连中十八期✔", url: "https://dsfsdf63.www59863a.com:888/#123" },
    { name: "管家婆论坛世高手资料已公开✔", url: "https://xvfdgfdg.www61865a.com:888/#123" },
    { name: "12码特图网④肖④码期期中奖✔", url: "https://cxgfdgdsf.www61312a.com:888/#123" },
    { name: "春宫图论坛六码中特已经上料✔", url: "https://xcfdsgdfg.www65952a.com:888/#123" },
    { name: "澳九肖十码复试四连期期中奖✔", url: "https://asdfdcds.www81972a.com:888/#123" },
    { name: "澳门管家婆四肖八码码已经上料✔", url: "https://sacvrgw.www81983a.com:888/#123" },
    { name: "澳门龙门客栈三肖六码连连中✔", url: "https://vxcvssdg.www19623a.com:888/#123" },
    { name: "澳门九肖30码高手资料已公开✔", url: "https://asdcxztg.www36915a.com:888/#123" },
    { name: "澳门大三巴八精准中特✔", url: "https://gfreqasca.www97185c.com:8443/#123" },
    { name: "今日闲情2复试四连期期中奖✔", url: "https://csafeqca.www58265a.com:888/#123" },
    { name: "澳门研究院收集各类澳彩资料✔", url: "https://r53pc7.www63539a.com:888/#123" },
    { name: "港澳百家网三肖六码已经上料✔", url: "https://ohv0fe.www71873c.com:888/#ztj" },
    { name: "澳门中特网三肖六码已经上料✔", url: "https://8pej1i.www23615a.com:8443/#123" },
    { name: "澳门小龙女四肖中特已经公开✔", url: "https://vvvvvx.www96153c.com:8443/#123" }
]

export const urlList4 = [  //澳门
    { name: "澳王中王网(八肖中特)→72385.com", url: "https://dsjkjkjkj.www72385c.com:8443/#123zutui" },
    { name: "澳利澳论坛(六肖18码)→68729.com", url: "https://alaaaa.www68729a.com:888/#123" },
    { name: "澳大三巴(精准中特)→97185.com", url: "https://gfreqasca.www97185c.com:8443/#123zutui" },
    { name: "澳小龙女(精准连中)→96135.com", url: "https://vvvvvx.www96153c.com:8443/#123zutui" },
    { name: "六合宝典网(①肖连中)→73125c.com", url: "https://19bjwc.www73125c.com:888/#123zutui" },
    { name: "澳九肖十码(床头四码)→81972.com", url: "https://asdfdcds.www81972a.com:888/#123zutui" },
    { name: "龙门客栈网(天机三码)→19623.com", url: "https://vxcvssdg.www19623a.com:888/#123zutui" },
    { name: "澳九肖30码(七码中特)→36915.com", url: "https://asdcxztg.www36915a.com:888/#123zutui" },
    { name: "澳彩管家婆(复试3中3)→81983.com", url: "https://sacvrgw.www81983a.com:888/#123zutui" },
    { name: "澳今日闲情2(四肖四码)→58265.com", url: "https://csafeqca.www58265a.com:888/#123zutui" },
    { name: "港澳百家网(②肖③码)→71156.com", url: "https://ohv0fe.www71873c.com:888/#ztj" },
    { name: "澳彩研究院(原创四肖)→63539.com", url: "https://r53pc7.www63539a.com:888/#123zutui" },
    { name: "30码中特网(30码中特)→58652a.com", url: "https://dsadas.www58652a.com:888/#123zutui" },
    { name: "一句真言网(复试3中3)→59863a.com", url: "https://dsfsdf63.www59863a.com:888/#123zutui" },
    { name: "12码特图网(四肖四码)→61312a.com", url: "https://cxgfdgdsf.www61312a.com:888/#123zutui" },
    { name: "管家婆论坛(一码救世)→61865a.com", url: "https://xvfdgfdg.www61865a.com:888/#123zutui" },
    { name: "春宫图论坛(②肖③码)→65952a.com", url: "https://xcfdsgdfg.www65952a.com:888/#123zutui" },
    { name: "澳彩杀料网(精杀九肖)→19721.com", url: "https://59cvv8.www19721a.com:8443/#123zutui" },
    { name: "澳彩挂牌网(挂牌③肖)→95287.com", url: "https://fnvon7.www95287a.com:8443/#123zutui" },
    { name: "澳彩中特网(玄机四码)→23615.com", url: "https://8pej1i.www23615a.com:8443/#123zutui" },
    { name: "澳彩金码网(每期三肖)→15761.com", url: "https://cep1ut.www15761a.com:8443/#123zutui" },
    { name: "澳彩手机网(开奖八码)→123861.com", url: "https://kjvh1c.www123861a.com:8443/#123zutui" },
    { name: "港澳博彩网(港澳⑦码)→18371.com", url: "https://1et3f2.www18371a.com:8443/#123zutui" },
    { name: "澳彩论坛网(精准3中3)→21531.com", url: "https://b3sxtt.www21531a.com:8443/#123zutui" },
    { name: "澳彩彩民网(彩民五码)→25261.com", url: "https://esjos1.www25261a.com:8443/#123zutui" },
    { name: "澳彩金钥匙(暴庄2中2)→87237.com", url: "https://fbaf5q.www87237a.com:8443/#123zutui" },
    { name: "澳彩雷锋网(雷锋四肖)→819292b.com", url: "https://29961.cc/#123zutui" },
    { name: "澳彩红姐网(红姐四肖)→26283.com", url: "https://6u3f27.www26283a.com:8443/#123zutui" },
    { name: "澳彩淘码网(主攻复试)→27921.com", url: "https://g5hg3h.www27921a.com:8443/#123zutui" },
    { name: "澳彩宝马网(四肖四码)→31793.com", url: "https://51h9te.www31793a.com:8443/#123zutui" },
    { name: "台湾六合彩(图库三肖)→00886tk.com", url: "https://00886tk.com/#123zutui" },
    { name: "澳彩马经网(一肖特图)→28291.com", url: "https://972myn.www28291a.com:8443/#123zutui" },
    { name: "澳彩西游网(西游三肖)→79591.com", url: "https://8zj9xn.www79591a.com:8443/#123zutui" },
    { name: "六合大亨网(团队八码)→28673.com", url: "https://h8fk3n.www28673a.com:8443/#123zutui" },
    { name: "今日闲情网(四肖八码)→15637.com", url: "https://wf6dph.www15637a.com:8443/#123zutui" },
    { name: "幽默玄机网(精准五码)→52785.com", url: "https://3khjtw.www52785a.com:8443/#123zutui" },
    { name: "百晓生论坛(独家二肖)→52639.com", url: "https://n28j9n.www52639a.com:8443/#123zutui" },
    { name: "澳彩六合味(三肖中特)→52817.com", url: "https://8ish7w.www52817a.com:8443/#123zutui" },
    { name: "澳彩美人鱼(连中单双)→28715.com", url: "https://1s6nhv.www28715a.com:8443/#123zutui" },
    { name: "澳彩白小姐(床头四码)→31976.com", url: "https://2qqhmn.www31976a.com:8443/#123zutui" },
    { name: "凤凰天机网(天机三码)→52832.com", url: "https://fbd1af.www52832a.com:8443/#123zutui" },
    { name: "澳彩图库网(七码中特)→49853a.com", url: "https://49853a.com/#123zutui" },
    { name: "澳彩管家婆(复试3中3)→28751.com", url: "https://au75qy.www28751a.com:8443/#123zutui" },
    { name: "澳彩夜明珠(四肖四码)→56521.com", url: "https://m1scyp.www56521a.com:8443/#123zutui" },
    { name: "观音救世网(一码救世)→28793.com", url: "https://thmca8.www28793a.com:8443/#123zutui" },
    { name: "澳彩一点红(②肖③码)→53183.com", url: "https://fgy3zz.www53183a.com:8443/#123zutui" }
]


export const urlList5 = [  //香港
    { name: "香港宝典网(公开⑥码)→822123.com", url: "https://tgxg.822123aa.com/#123zutui" },
    { name: "香港内幕网(实力②肖)→42193.com", url: "https://42193.com/#123zutui" },
    { name: "香港六合宝典(领取一码)→8227788.com", url: "https://xg.9898yz.com:9898/#123zutui" },
    { name: "香港中特网(三肖三码)→556676.com", url: "https://uhgzbc.www556676a.com:8443/#123zutui" },
    { name: "香港金码网(六肖六码)→576611.cc", url: "https://136611b.com/#123zutui" },
    { name: "香港手机站(开奖④码)→661163.com", url: "https://qpnuyv.www661163a.com:8443/#123zutui" },
    { name: "香港图库网(彩图一肖)→49852b.com", url: "https://49852b.com/#123zutui" },
    { name: "香港挂牌网(挂牌三肖)→336625.com", url: "https://cccfny.www336625a.com:8443/#123zutui" },
    { name: "香港八卦会(八卦③肖)→227771.com", url: "https://msvq1e.www227771a.com:8443/#123zutui" },
    { name: "香港开奖(计算四码)→551163.com", url: "https://tgavvx.www551163a.com:8443/#123zutui" },
    { name: "皇博神算网(神算③肖)→178855a.com", url: "https://1et3f2.www18371a.com:8443/#123zutui" },
    { name: "天龙论坛网(复试3中3)→558839.com", url: "https://bdoepg.www558839a.com:8443/#123zutui" },
    { name: "香港管家婆(家婆三码)→668869.com", url: "https://uodrnd.www668869a.com:8443/#123zutui" },
    { name: "香港马经网(马经二码)→662221.com", url: "https://6zj8vz.www662221a.com:8443/#123zutui" },
    { name: "天天好彩网(精准一码)→24639.com", url: "https://www.24639.com/#123zutui" },
    { name: "六合宝典网(三肖六码)→885526.com", url: "https://ieddxn.www885526a.com:8443/#123zutui" },
    { name: "三十计论坛(计谋12码)→779968.com", url: "https://pkzkae.www779968a.com:8443/#123zutui" },
    { name: "香港赛马会(马会三肖)→772221.com", url: "https://fafz58.www772221a.com:8443/#123zutui" },
    { name: "香港今日闲情(每期④码)→227758.com", url: "https://v0n778.www227758a.com:8443/#123zutui" },
    { name: "美人鱼论坛(连中单双)→773397.com", url: "https://aoxlss.www773397a.com:8443/#123zutui" },
    { name: "香港论坛网(主打四肖)→226629.com", url: "https://ngxxjn.www226629a.com:8443/#123zutui" },
    { name: "香港刘伯温(伯温四码)→689911.com", url: "https://0be4lq.www689911a.com:8443/#123zutui" },
    { name: "香港彩民网(③肖③码)→339957.com", url: "https://5s8rdc.www339957a.com:8443/#123zutui" },
    { name: "台湾图库网(一码中特图)→00886tk.com", url: "https://00886tk.com/#123zutui" },
    { name: "香港红姐网(独家三肖)→557783.com", url: "https://g8zmrq.www557783a.com:8443/#123zutui" },
    { name: "香港淘码网(主打2中2)→661139.com", url: "https://ypme30.www661139a.com:8443/#123zutui" },
    { name: "香港宝马站(宝马十码)→332297.com", url: "https://oyp6l0.www332297a.com:8443/#123zutui" },
    { name: "香港西游网(西游三码)→776693.com", url: "https://eul3rv.www776693a.com:8443/#123zutui" },
    { name: "六合大亨网(团队十码)→557782.com", url: "https://2g8u65.www557782a.com:8443/#123zutui" },
    { name: "幽默玄机网(一肖而码)→338869.com", url: "https://dbi66v.www338869a.com:8443/#123zutui" },
    { name: "香港百晓生(原创③肖)→339975.com", url: "https://9510ra.www339975a.com:8443/#123zutui" },
    { name: "香港六合味(男人四码)→335528.com", url: "https://z7rf8c.www335528a.com:8443/#123zutui" },
    { name: "凤凰天机网(每期六码)→552278.com", url: "https://s25zcg.www552278a.com:8443/#123zutui" },
    { name: "香港夜明珠(三肖五码)→116691.com", url: "https://kidoe7.www116691a.com:8443/#123zutui" },
    { name: "观音救世网(救世三肖)→221185.com", url: "https://7q6m3h.www221185a.com:8443/#123zutui" },
    { name: "一点红论坛(八码不防)→221196.cc", url: "https://wic526.www221196a.com:8443/#123zutui" },
    { name: "视频直播网(公开一肖)→221136.com", url: "https://e9nh3y.www221136a.com:8443/#123zutui" }
]


export const urlList6 = [  //台湾
    { name: "台湾开奖站21527.com", url: "https://qazsss.www21527a.com:8443/#23" },
    { name: "台湾澳利澳26139.com", url: "https://zxcwsx.www26139a.com:8443/#23" },
    { name: "台湾白小姐28131.com", url: "https://tgbffyy.www28131a.com:8443/#23" },
    { name: "台湾挂牌网19715.com", url: "https://dsfdsf.www19715a.com:8443/#23" },
    { name: "香格里拉四肖八码", url: "https://asdsadas.www995521a.com:888/kongj/a0006.html" },
    { name: "万人之上三尾六码", url: "https://asdsadas.www995521a.com:888/kongj/a0005.html" },
    { name: "台湾木子平特一尾", url: "https://asdsadas.www995521a.com:888/kongj/a0002.html" },
    { name: "伍佰演唱挪威六肖", url: "https://asdsadas.www995521a.com:888/kongj/a0007.html" }
]
// 图纸
// 澳门
export const tuzhiaomen = [
    { name: "澳彩龙门客栈", url: "https://h5.49217008.com:8443/#/picture/417013" },
    { name: "澳彩青龙报", url: "https://h5.49217008.com:8443/#/picture/28025" },
    { name: "正版射牌澳", url: "https://h5.49217008.com:8443/#/picture/28012" },
    { name: "诗象破成语", url: "https://h5.49217008.com:8443/#/picture/210086" },
    { name: "宝宝平特1肖", url: "https://h5.49217008.com:8443/#/picture/209106" },
    { name: "平特一肖图", url: "https://h5.49217008.com:8443/#/picture/28240" },
    { name: "无错9肖10码", url: "https://h5.49217008.com:8443/#/picture/210079" },
    { name: "澳彩天书三肖", url: "https://290123.com/zbjy/230.htm" },
    { name: "澳彩火麒麟", url: "https://h5.49217008.com:8443/#/picture/209351" },
    { name: "正版四不像", url: "https://h5.49217008.com:8443/#/picture/28013" },
    { name: "正版澳彩传真", url: "https://h5.49217008.com:8443/#/picture/28022" },
    { name: "美人鱼单双", url: "https://h5.49217008.com:8443/#/picture/209185" },
    { name: "看图找生肖", url: "https://h5.49217008.com:8443/#/picture/209362" },
    { name: "澳彩客家娘", url: "https://h5.49217008.com:8443/#/picture/28100" },
    { name: "老藏宝图", url: "https://h5.49217008.com:8443/#/picture/28966" },
    { name: "正版藏宝图", url: "https://h5.49217008.com:8443/#/picture/28693" },
    { name: "平特藏宝图", url: "https://h5.49217008.com:8443/#/picture/28233" },
    { name: "澳彩红虎报", url: "https://h5.49217008.com:8443/#/picture/28028" },
    { name: "澳彩跑马图", url: "https://h5.49217008.com:8443/#/picture/28017" },
    { name: "管家婆1句话", url: "https://h5.49217008.com:8443/#/picture/28015" },
    { name: "八仙过海澳", url: "https://h5.49217008.com:8443/#/picture/28019" },
    { name: "30码中特图", url: "https://h5.49217008.com:8443/#/picture/28026" },
    { name: "四组三连澳", url: "https://h5.49217008.com:8443/#/picture/28024" },
    { name: "凤凰天机图", url: "https://h5.49217008.com:8443/#/picture/28086" },
    { name: "东成西就澳", url: "https://h5.49217008.com:8443/#/picture/209232" },
    { name: "澳门欲钱料", url: "https://h5.49217008.com:8443/#/picture/209469" },
    { name: "正版挂牌图", url: "https://h5.49217008.com:8443/#/picture/28021" },
    { name: "澳彩孩童报", url: "https://h5.49217008.com:8443/#/picture/28018" },
    { name: "一字解特码", url: "https://h5.49217008.com:8443/#/picture/28014" },
    { name: "单双中特图", url: "https://h5.49217008.com:8443/#/picture/28020" },
    { name: "通天报解码", url: "https://h5.49217008.com:8443/#/picture/28016" },
    { name: "澳彩夜城", url: "https://h5.49217008.com:8443/#/picture/28091" },
    { name: "澳彩玄机图", url: "https://h5.49217008.com:8443/#/picture/28047" },
    { name: "澳彩女人味", url: "https://h5.49217008.com:8443/#/picture/28027" },
    { name: "玄机妙语澳", url: "https://h5.49217008.com:8443/#/picture/28063" },
    { name: "佛主禁肖澳", url: "https://h5.49217008.com:8443/#/picture/28058" },
    { name: "传真玄机诗", url: "https://h5.49217008.com:8443/#/picture/28056" },
    { name: "特码玄机图", url: "https://h5.49217008.com:8443/#/picture/28055" },
    { name: "赌王心水报", url: "https://h5.49217008.com:8443/#/picture/28049" },
    { name: "正版财神报", url: "https://h5.49217008.com:8443/#/picture/28023" },
    { name: "济公救民报", url: "https://h5.49217008.com:8443/#/picture/28098" },
    { name: "澳彩老人味", url: "https://h5.49217008.com:8443/#/picture/28108" },
    { name: "幽默玄机图", url: "https://h5.49217008.com:8443/#/picture/28050" },
    { name: "财神玄机报", url: "https://h5.49217008.com:8443/#/picture/28111" },
    { name: "澳彩签牌图", url: "https://h5.49217008.com:8443/#/picture/28096" },
    { name: "女财神报澳", url: "https://h5.49217008.com:8443/#/picture/28099" },
    { name: "凤凰卜卦澳", url: "https://h5.49217008.com:8443/#/picture/28093" },
    { name: "12码中特图", url: "https://h5.49217008.com:8443/#/picture/28051" },
    { name: "挂牌平特报", url: "https://h5.49217008.com:8443/#/picture/28059" },
    { name: "观音彩报图", url: "https://h5.49217008.com:8443/#/picture/28048" },
    { name: "七句真言图", url: "https://h5.49217008.com:8443/#/picture/28057" },
    { name: "值日生肖图", url: "https://h5.49217008.com:8443/#/picture/28052" },
    { name: "澳彩火凤凰", url: "https://h5.49217008.com:8443/#/picture/209347" },
    { name: "澳彩跑狗图", url: "https://h5.49217008.com:8443/#/picture/28089" },
    { name: "澳彩七星彩", url: "https://h5.49217008.com:8443/#/picture/28082" },
    { name: "看图抓码澳", url: "https://h5.49217008.com:8443/#/picture/28070" },
    { name: "功夫早茶澳", url: "https://h5.49217008.com:8443/#/picture/28081" },
    { name: "鬼谷子报特", url: "https://h5.49217008.com:8443/#/picture/28083" },
    { name: "黄道吉日报", url: "https://h5.49217008.com:8443/#/picture/28062" },
    { name: "今日闲情二", url: "https://h5.49217008.com:8443/#/picture/28069" },
    { name: "金多宝传真", url: "https://h5.49217008.com:8443/#/picture/28073" },
    { name: "胜算策略报", url: "https://h5.49217008.com:8443/#/picture/28144" },
    { name: "金钱报功夫", url: "https://h5.49217008.com:8443/#/picture/28085" },
    { name: "澳彩开心果", url: "https://h5.49217008.com:8443/#/picture/28068" },
    { name: "今日闲情澳", url: "https://h5.49217008.com:8443/#/picture/28077" },
    { name: "看图解特码", url: "https://h5.49217008.com:8443/#/picture/28076" },
    { name: "六合头条澳", url: "https://h5.49217008.com:8443/#/picture/28064" },
    { name: "另版跑狗图", url: "https://h5.49217008.com:8443/#/picture/28090" },
    { name: "老版跑狗图", url: "https://h5.49217008.com:8443/#/picture/28092" },
    { name: "生活幽默报", url: "https://h5.49217008.com:8443/#/picture/28078" },
    { name: "澳彩码头诗", url: "https://h5.49217008.com:8443/#/picture/28071" },
    { name: "每日闲情图", url: "https://h5.49217008.com:8443/#/picture/28079" },
    { name: "四不像玄机", url: "https://h5.49217008.com:8443/#/picture/28067" },
    { name: "铁板神算澳", url: "https://h5.49217008.com:8443/#/picture/28065" },
    { name: "幽默猜测澳", url: "https://h5.49217008.com:8443/#/picture/28080" },
    { name: "澳彩蛇蛋图", url: "https://h5.49217008.com:8443/#/picture/28072" },
    { name: "周公玄机报", url: "https://h5.49217008.com:8443/#/picture/28074" },
    { name: "36码中特图", url: "https://h5.49217008.com:8443/#/picture/28084" },
    { name: "庄家吃码图", url: "https://h5.49217008.com:8443/#/picture/28088" },
    { name: "澳彩男人味", url: "https://h5.49217008.com:8443/#/picture/28094" },
    { name: "八仙指路澳", url: "https://h5.49217008.com:8443/#/picture/28060" },
    { name: "白小姐会员", url: "https://h5.49217008.com:8443/#/picture/28492" },
    { name: "白小姐密传", url: "https://h5.49217008.com:8443/#/picture/28587" },
    { name: "波叔一波中", url: "https://h5.49217008.com:8443/#/picture/28844" },
    { name: "波肖尾门报", url: "https://h5.49217008.com:8443/#/picture/28324" },
    { name: "传真八点料", url: "https://h5.49217008.com:8443/#/picture/28495" },
    { name: "澳彩大陆报", url: "https://h5.49217008.com:8443/#/picture/28795" },
    { name: "澳彩大陆仔", url: "https://h5.49217008.com:8443/#/picture/28762" },
    { name: "二尾四码图", url: "https://h5.49217008.com:8443/#/picture/28101" },
    { name: "凤姐30码澳", url: "https://h5.49217008.com:8443/#/picture/28097" },
    { name: "发财波局报", url: "https://h5.49217008.com:8443/#/picture/28588" },
    { name: "管家婆密传", url: "https://h5.49217008.com:8443/#/picture/28491" },
    { name: "马会火烧图", url: "https://h5.49217008.com:8443/#/picture/28250" },
    { name: "红姐内幕图", url: "https://h5.49217008.com:8443/#/picture/28380" },
    { name: "话中有意澳", url: "https://h5.49217008.com:8443/#/picture/28379" },
    { name: "劲爆龙凤榜", url: "https://h5.49217008.com:8443/#/picture/28589" },
    { name: "狼女侠客图", url: "https://h5.49217008.com:8443/#/picture/28095" },
    { name: "挂牌内幕图", url: "https://h5.49217008.com:8443/#/picture/209367" },
    { name: "六合简报图", url: "https://h5.49217008.com:8443/#/picture/28125" },
    { name: "六合英雄报", url: "https://h5.49217008.com:8443/#/picture/28342" },
    { name: "六合家宝A", url: "https://h5.49217008.com:8443/#/picture/28738" },
    { name: "六合家宝B", url: "https://h5.49217008.com:8443/#/picture/28736" },
    { name: "澳彩猛虎报", url: "https://h5.49217008.com:8443/#/picture/28029" },
    { name: "梦儿数码报", url: "https://h5.49217008.com:8443/#/picture/28913" },
    { name: "内部传真澳", url: "https://h5.49217008.com:8443/#/picture/28907" },
    { name: "澳彩牛头报", url: "https://h5.49217008.com:8443/#/picture/28285" },
    { name: "内幕特肖B", url: "https://h5.49217008.com:8443/#/picture/209039" },
    { name: "内幕特肖A", url: "https://h5.49217008.com:8443/#/picture/209036" },
    { name: "澳彩宝典报", url: "https://h5.49217008.com:8443/#/picture/28239" },
    { name: "趣味幽默澳", url: "https://h5.49217008.com:8443/#/picture/28075" },
    { name: "千手观音图", url: "https://h5.49217008.com:8443/#/picture/28117" },
    { name: "七肖中特澳", url: "https://h5.49217008.com:8443/#/picture/28793" },
    { name: "神奇八卦图", url: "https://h5.49217008.com:8443/#/picture/28054" },
    { name: "澳彩神算报", url: "https://h5.49217008.com:8443/#/picture/28591" },
    { name: "数码挂牌澳", url: "https://h5.49217008.com:8443/#/picture/28486" },
    { name: "神童透码报", url: "https://h5.49217008.com:8443/#/picture/28277" },
    { name: "三八婆密报", url: "https://h5.49217008.com:8443/#/picture/28496" },
    { name: "三怪禁肖图", url: "https://h5.49217008.com:8443/#/picture/28894" },
    { name: "正版通天报", url: "https://h5.49217008.com:8443/#/picture/28494" },
    { name: "特码诗句澳", url: "https://h5.49217008.com:8443/#/picture/28061" },
    { name: "图库禁肖澳", url: "https://h5.49217008.com:8443/#/picture/28585" },
    { name: "特码快递澳", url: "https://h5.49217008.com:8443/#/picture/28385" },
    { name: "无敌猪哥报", url: "https://h5.49217008.com:8443/#/picture/28224" },
    { name: "赛马会杀肖", url: "https://h5.49217008.com:8443/#/picture/209029" },
    { name: "鱼跃龙门报", url: "https://h5.49217008.com:8443/#/picture/28053" },
    { name: "一句真言澳", url: "https://h5.49217008.com:8443/#/picture/28087" },
    { name: "关公砍一刀", url: "https://h5.49217008.com:8443/#/picture/209406" },
    { name: "曾道人暗语", url: "https://h5.49217008.com:8443/#/picture/28189" },
    { name: "澳彩抓码王", url: "https://h5.49217008.com:8443/#/picture/28210" },
    { name: "赛马会杀波", url: "https://h5.49217008.com:8443/#/picture/28807" },
    { name: "熊出没幽默", url: "https://h5.49217008.com:8443/#/picture/28066" },
    { name: "澳彩一样发", url: "https://h5.49217008.com:8443/#/picture/28116" },
    { name: "正版天线宝", url: "https://h5.49217008.com:8443/#/picture/28146" },
    { name: "宝中之宝澳", url: "https://h5.49217008.com:8443/#/picture/28764" },
    { name: "白小姐救世B", url: "https://h5.49217008.com:8443/#/picture/28646" },
    { name: "白小姐救世A", url: "https://h5.49217008.com:8443/#/picture/28656" },
    { name: "爆码料B", url: "https://h5.49217008.com:8443/#/picture/28663" },
    { name: "爆码料A", url: "https://h5.49217008.com:8443/#/picture/28664" },
    { name: "帮您翻本", url: "https://h5.49217008.com:8443/#/picture/28725" },
    { name: "澳彩豹女郎", url: "https://h5.49217008.com:8443/#/picture/28201" },
    { name: "澳彩八仙图", url: "https://h5.49217008.com:8443/#/picture/28202" },
    { name: "博彩快讯报", url: "https://h5.49217008.com:8443/#/picture/209050" },
    { name: "波行肖尾特", url: "https://h5.49217008.com:8443/#/picture/28798" },
    { name: "宝宝特码图", url: "https://h5.49217008.com:8443/#/picture/28831" },
    { name: "博彩通B", url: "https://h5.49217008.com:8443/#/picture/28960" },
    { name: "博彩通A", url: "https://h5.49217008.com:8443/#/picture/28962" },
    { name: "澳彩包租婆", url: "https://h5.49217008.com:8443/#/picture/28169" },
    { name: "澳彩百蛇图", url: "https://h5.49217008.com:8443/#/picture/28226" },
    { name: "博彩平特报", url: "https://h5.49217008.com:8443/#/picture/28885" },
    { name: "百家乐玄机", url: "https://h5.49217008.com:8443/#/picture/28886" },
    { name: "宝宝平特", url: "https://h5.49217008.com:8443/#/picture/209111" },
    { name: "精选八肖图", url: "https://h5.49217008.com:8443/#/picture/209108" },
    { name: "不服来战澳", url: "https://h5.49217008.com:8443/#/picture/209139" },
    { name: "澳彩老鼠报", url: "https://h5.49217008.com:8443/#/picture/28642" },
    { name: "彩霸王六肖", url: "https://h5.49217008.com:8443/#/picture/209107" },
    { name: "东方心经B", url: "https://h5.49217008.com:8443/#/picture/28475" },
    { name: "东方心经A", url: "https://h5.49217008.com:8443/#/picture/28471" },
    { name: "心经加大版B", url: "https://h5.49217008.com:8443/#/picture/28524" },
    { name: "心经加大版A", url: "https://h5.49217008.com:8443/#/picture/28523" },
    { name: "关公砍杀澳", url: "https://h5.49217008.com:8443/#/picture/28691" },
    { name: "挂牌天书澳", url: "https://h5.49217008.com:8443/#/picture/28859" },
    { name: "挂牌解密报", url: "https://h5.49217008.com:8443/#/picture/28850" },
    { name: "挂牌玄机图", url: "https://h5.49217008.com:8443/#/picture/28854" },
    { name: "挂牌寻宝图", url: "https://h5.49217008.com:8443/#/picture/28852" },
    { name: "澳彩管家婆", url: "https://h5.49217008.com:8443/#/picture/28698" },
    { name: "管家婆财经", url: "https://h5.49217008.com:8443/#/picture/28584" },
    { name: "管家婆心水A", url: "https://h5.49217008.com:8443/#/picture/28839" },
    { name: "管家婆心水B", url: "https://h5.49217008.com:8443/#/picture/28837" },
    { name: "广州传真诗", url: "https://h5.49217008.com:8443/#/picture/28487" },
    { name: "广州传真猜", url: "https://h5.49217008.com:8443/#/picture/28490" },
    { name: "广东赌王澳", url: "https://h5.49217008.com:8443/#/picture/28521" },
    { name: "黄大仙发财", url: "https://h5.49217008.com:8443/#/picture/28247" },
    { name: "黄大仙神圣B", url: "https://h5.49217008.com:8443/#/picture/28751" },
    { name: "黄大仙神圣A", url: "https://h5.49217008.com:8443/#/picture/28750" },
    { name: "胡巴抓码记", url: "https://h5.49217008.com:8443/#/picture/209159" },
    { name: "澳彩紫财神", url: "https://h5.49217008.com:8443/#/picture/209328" },
    { name: "澳彩花仙子", url: "https://h5.49217008.com:8443/#/picture/209188" },
    { name: "澳彩平财神", url: "https://h5.49217008.com:8443/#/picture/209191" },
    { name: "澳彩青财神", url: "https://h5.49217008.com:8443/#/picture/209189" },
    { name: "澳彩银财神", url: "https://h5.49217008.com:8443/#/picture/209329" },
    { name: "澳彩金财神", url: "https://h5.49217008.com:8443/#/picture/209187" },
    { name: "澳彩蓝财神", url: "https://h5.49217008.com:8443/#/picture/209190" },
    { name: "澳彩绿财神", url: "https://h5.49217008.com:8443/#/picture/209193" },
    { name: "澳彩黄财神", url: "https://h5.49217008.com:8443/#/picture/209192" },
    { name: "美女六肖图", url: "https://h5.49217008.com:8443/#/picture/28511" },
    { name: "假老藏宝图", url: "https://h5.49217008.com:8443/#/picture/28821" },
    { name: "假新藏宝图", url: "https://h5.49217008.com:8443/#/picture/28832" },
    { name: "九龙内幕传", url: "https://h5.49217008.com:8443/#/picture/28627" },
    { name: "刘半仙哑谜", url: "https://h5.49217008.com:8443/#/picture/28905" },
    { name: "平特内幕报", url: "https://h5.49217008.com:8443/#/picture/28231" },
    { name: "平特王日报", url: "https://h5.49217008.com:8443/#/picture/28228" },
    { name: "澳彩频果报", url: "https://h5.49217008.com:8443/#/picture/28534" },
    { name: "如来秘报澳", url: "https://h5.49217008.com:8443/#/picture/209153" },
    { name: "神医药方澳", url: "https://h5.49217008.com:8443/#/picture/28128" },
    { name: "澳彩铁算盘", url: "https://h5.49217008.com:8443/#/picture/28138" },
    { name: "新报跑狗背", url: "https://h5.49217008.com:8443/#/picture/28961" },
    { name: "新报跑狗正", url: "https://h5.49217008.com:8443/#/picture/28959" },
    { name: "新报跑狗B", url: "https://h5.49217008.com:8443/#/picture/28625" },
    { name: "新报跑狗A", url: "https://h5.49217008.com:8443/#/picture/28629" },
    { name: "想入非非澳", url: "https://h5.49217008.com:8443/#/picture/209186" },
    { name: "一肖平特图", url: "https://h5.49217008.com:8443/#/picture/28512" },
    { name: "正版2800信封", url: "https://h5.49217008.com:8443/#/picture/28565" },
    { name: "澳彩藏宝图", url: "https://h5.49217008.com:8443/#/picture/209114" },
    { name: "麒麟透码报", url: "https://h5.49217008.com:8443/#/picture/209134" },
    { name: "金钥匙导报", url: "https://h5.49217008.com:8443/#/picture/28884" }
]

export const tuzhixianggang = [
    { name: "点击查看更多图纸", url: "https://j.manolotron.com:49/" },
    { name: "正版四不像", url: "https://h5.49217008.com:8443/#/picture/12679" },
    { name: "正版马会传真", url: "https://h5.49217008.com:8443/#/picture/12683" },
    { name: "港正版挂牌", url: "https://h5.49217008.com:8443/#/picture/10839" },
    { name: "特码王(准)", url: "https://h5.49217008.com:8443/#/picture/10507" },
    { name: "香港青龙报", url: "https://h5.49217008.com:8443/#/picture/15819" },
    { name: "港正版射牌", url: "https://h5.49217008.com:8443/#/picture/15904" },
    { name: "新跑狗图", url: "https://h5.49217008.com:8443/#/picture/12690" },
    { name: "老跑狗图", url: "https://h5.49217008.com:8443/#/picture/12715" },
    { name: "另跑狗图", url: "https://h5.49217008.com:8443/#/picture/15855" },
    { name: "港九肖十码", url: "https://h5.49217008.com:8443/#/picture/15966" },
    { name: "港看图抓码", url: "https://h5.49217008.com:8443/#/picture/15920" },
    { name: "优雅心水", url: "https://h5.49217008.com:8443/#/picture/15911" },
    { name: "香港红虎报", url: "https://h5.49217008.com:8443/#/picture/15902" },
    { name: "八仙过海图", url: "https://h5.49217008.com:8443/#/picture/10667" },
    { name: "管家婆1句话", url: "https://h5.49217008.com:8443/#/picture/15824" },
    { name: "金陵十二钗", url: "https://h5.49217008.com:8443/#/picture/12923" },
    { name: "果妹资料", url: "https://h5.49217008.com:8443/#/picture/15963" },
    { name: "   香港欲钱料", url: "https://h5.49217008.com:8443/#/picture/15974" },
    { name: "香港玄机图", url: "https://h5.49217008.com:8443/#/picture/15968" },
    { name: "诗象破成语", url: "https://h5.49217008.com:8443/#/picture/15967" },
    { name: "   关公杀一肖", url: "https://h5.49217008.com:8443/#/picture/15962" },
    { name: "江小白四肖", url: "https://h5.49217008.com:8443/#/picture/15921" },
    { name: "内幕3肖6码", url: "https://h5.49217008.com:8443/#/picture/15976" },
    { name: "   正版西游报", url: "https://h5.49217008.com:8443/#/picture/15973" },
    { name: "奇神卦八肖", url: "https://h5.49217008.com:8443/#/picture/15975" },
    { name: "独家6肖12码", url: "https://h5.49217008.com:8443/#/picture/15971" },
    { name: "   港彩票中心", url: "https://h5.49217008.com:8443/#/picture/15972" },
    { name: "香港秘典", url: "https://h5.49217008.com:8443/#/picture/15883" },
    { name: "读者论坛", url: "https://h5.49217008.com:8443/#/picture/15837" },
    { name: " 东南漫画", url: "https://h5.49217008.com:8443/#/picture/15860" },
    { name: "六合头条", url: "https://h5.49217008.com:8443/#/picture/15831" },
    { name: "广州日报", url: "https://h5.49217008.com:8443/#/picture/15910" },
    { name: " 凌波微步", url: "https://h5.49217008.com:8443/#/picture/15825" },
    { name: "踏雪无痕", url: "https://h5.49217008.com:8443/#/picture/15865" },
    { name: "富婆传密", url: "https://h5.49217008.com:8443/#/picture/15935" },
    { name: " 创富绝杀", url: "https://h5.49217008.com:8443/#/picture/15914" },
    { name: "悟入其图", url: "https://h5.49217008.com:8443/#/picture/15849" },
    { name: "想入非非", url: "https://h5.49217008.com:8443/#/picture/15821" },
    { name: " 正版管家婆", url: "https://h5.49217008.com:8443/#/picture/10723" },
    { name: "香港商报A", url: "https://h5.49217008.com:8443/#/picture/15956" },
    { name: "六合至尊", url: "https://h5.49217008.com:8443/#/picture/15956" },
    { name: " 财富赢家", url: "https://h5.49217008.com:8443/#/picture/15835" },
    { name: "丫丫幽默", url: "https://h5.49217008.com:8443/#/picture/15832" },
    { name: "马经王牌料", url: "https://h5.49217008.com:8443/#/picture/10867" },
    { name: " 正版通天报", url: "https://h5.49217008.com:8443/#/picture/10847" },
    { name: "香港紫财神", url: "https://h5.49217008.com:8443/#/picture/15918" },
    { name: "香港红财神", url: "https://h5.49217008.com:8443/#/picture/15842" },
    { name: "香港黄财神", url: "https://h5.49217008.com:8443/#/picture/15916" },
    { name: "香港蓝财神", url: "https://h5.49217008.com:8443/#/picture/15839" },
    { name: "香港银财神", url: "https://h5.49217008.com:8443/#/picture/15841" },
    { name: "香港金财神", url: "https://h5.49217008.com:8443/#/picture/15846" },
    { name: "香港青财神", url: "https://h5.49217008.com:8443/#/picture/15907" },
    { name: "香港平财神", url: "https://h5.49217008.com:8443/#/picture/15917" },
    { name: "香港绿财神", url: "https://h5.49217008.com:8443/#/picture/15838" },
    { name: "香港女财神", url: "https://h5.49217008.com:8443/#/picture/15847" },
    { name: "香港发财报", url: "https://h5.49217008.com:8443/#/picture/15848" },
    { name: "香港猛虎报", url: "https://h5.49217008.com:8443/#/picture/15844" },
    { name: "正版孩童图", url: "https://h5.49217008.com:8443/#/picture/10419" },
    { name: "老版藏宝图", url: "https://h5.49217008.com:8443/#/picture/10625" },
    { name: "正版藏宝图", url: "https://h5.49217008.com:8443/#/picture/10623" },
    { name: "香港值日生肖", url: "https://h5.49217008.com:8443/#/picture/15969" },
    { name: "香港铁板神算", url: "https://h5.49217008.com:8443/#/picture/15882" },
    { name: "   济公救民诗", url: "https://h5.49217008.com:8443/#/picture/15859" },
    { name: "港毛哥16码", url: "https://h5.49217008.com:8443/#/picture/15978" },
    { name: "独霸天下报", url: "https://h5.49217008.com:8443/#/picture/15941" },
    { name: "  天眼神算报", url: "https://h5.49217008.com:8443/#/picture/15936" },
    { name: "曾道人特码", url: "https://h5.49217008.com:8443/#/picture/15905" },
    { name: "香港公证处1", url: "https://h5.49217008.com:8443/#/picture/15886" },
    { name: " 香港公证处2", url: "https://h5.49217008.com:8443/#/picture/15885" },
    { name: "平特藏宝图", url: "https://h5.49217008.com:8443/#/picture/10882" },
    { name: "香港新粤彩", url: "https://h5.49217008.com:8443/#/picture/15870" },
    { name: "港七星图A", url: "https://h5.49217008.com:8443/#/picture/10560" },
    { name: "港七星图B", url: "https://h5.49217008.com:8443/#/picture/10561" },
    { name: "黄大仙发财", url: "https://h5.49217008.com:8443/#/picture/10972" },
    { name: "香港财神报", url: "https://h5.49217008.com:8443/#/picture/15817" },
    { name: "港一句真言", url: "https://h5.49217008.com:8443/#/picture/15960" },
    { name: "港功夫早茶", url: "https://h5.49217008.com:8443/#/picture/15830" },
    { name: "金钱豹功夫", url: "https://h5.49217008.com:8443/#/picture/15853" },
    { name: "鬼谷子爆1肖", url: "https://h5.49217008.com:8443/#/picture/15827" },
    { name: "金多宝传真", url: "https://h5.49217008.com:8443/#/picture/13013" },
    { name: "正版金多宝", url: "https://h5.49217008.com:8443/#/picture/15864" },
    { name: "港东成西就", url: "https://h5.49217008.com:8443/#/picture/15961" },
    { name: "创富投注图", url: "https://h5.49217008.com:8443/#/picture/15912" },
    { name: " 香港新周刊", url: "https://h5.49217008.com:8443/#/picture/15950" },
    { name: "六合皇港版A", url: "https://h5.49217008.com:8443/#/picture/15930" },
    { name: "六合皇港版B", url: "https://h5.49217008.com:8443/#/picture/15931" },
    { name: " 六合皇港版C", url: "https://h5.49217008.com:8443/#/picture/15932" },
    { name: "六合皇港版D", url: "https://h5.49217008.com:8443/#/picture/15933" },
    { name: "电邮天地港", url: "https://h5.49217008.com:8443/#/picture/15843" },
    { name: " 曾道人信箱", url: "https://h5.49217008.com:8443/#/picture/15840" },
    { name: "香港先锋报", url: "https://h5.49217008.com:8443/#/picture/15836" },
    { name: "马经四肖报", url: "https://h5.49217008.com:8443/#/picture/10768" },
    { name: " 香港老鼠报", url: "https://h5.49217008.com:8443/#/picture/10409" },
    { name: "港六合风莲", url: "https://h5.49217008.com:8443/#/picture/15874" },
    { name: "香港莲花篇", url: "https://h5.49217008.com:8443/#/picture/15873" },
    { name: " 香港金元宝", url: "https://h5.49217008.com:8443/#/picture/15872" },
    { name: "马经内幕报", url: "https://h5.49217008.com:8443/#/picture/12685" },
    { name: "香港商报B", url: "https://h5.49217008.com:8443/#/picture/15957" },
    { name: " 香港商报C", url: "https://h5.49217008.com:8443/#/picture/15957" },
    { name: "马经龙头报", url: "https://h5.49217008.com:8443/#/picture/10869" },
    { name: "马经特码报", url: "https://h5.49217008.com:8443/#/picture/10924" },
    { name: " 马经心水报", url: "https://h5.49217008.com:8443/#/picture/10940" },
    { name: "\\t蓝色欲钱料", url: "https://h5.49217008.com:8443/#/picture/15979" },
    { name: "香港赢站报", url: "https://h5.49217008.com:8443/#/picture/12947" },
    { name: " 香港小黄人", url: "https://h5.49217008.com:8443/#/picture/12942" },
    { name: "香港陆道人", url: "https://h5.49217008.com:8443/#/picture/12941" },
    { name: "香港为赢报", url: "https://h5.49217008.com:8443/#/picture/12940" },
    { name: " 港偷码玄机", url: "https://h5.49217008.com:8443/#/picture/12938" },
    { name: "旺角贴士皇", url: "https://h5.49217008.com:8443/#/picture/12939" },
    { name: "特码曲线图", url: "https://h5.49217008.com:8443/#/picture/12936" },
    { name: " 港天机信封", url: "https://h5.49217008.com:8443/#/picture/12937" },
    { name: "港如来密报", url: "https://h5.49217008.com:8443/#/picture/12932" },
    { name: "七剑下天山", url: "https://h5.49217008.com:8443/#/picture/12930" },
    { name: " 内幕迷典经", url: "https://h5.49217008.com:8443/#/picture/12929" },
    { name: "港新地藏图", url: "https://h5.49217008.com:8443/#/picture/12906" },
    { name: "新东方心经", url: "https://h5.49217008.com:8443/#/picture/12908" },
    { name: " 灯谜猜特码", url: "https://h5.49217008.com:8443/#/picture/15857" },
    { name: "港挂牌天书", url: "https://h5.49217008.com:8443/#/picture/10873" },
    { name: "港数码挂牌", url: "https://h5.49217008.com:8443/#/picture/10857" },
    { name: "今日闲情一", url: "https://h5.49217008.com:8443/#/picture/15816" },
    { name: "今日闲情二", url: "https://h5.49217008.com:8443/#/picture/15820" },
    { name: "香港蛇蛋图", url: "https://h5.49217008.com:8443/#/picture/12892" },
    { name: "港幽默猜测", url: "https://h5.49217008.com:8443/#/picture/12891" },
    { name: "观音解签报", url: "https://h5.49217008.com:8443/#/picture/12914" },
    { name: "香港财神到", url: "https://h5.49217008.com:8443/#/picture/12688" },
    { name: "香港凤凰报", url: "https://h5.49217008.com:8443/#/picture/12911" },
    { name: "姜太公神算", url: "https://h5.49217008.com:8443/#/picture/15858" },
    { name: "看图解特码", url: "https://h5.49217008.com:8443/#/picture/15829" },
    { name: "观音救世报", url: "https://h5.49217008.com:8443/#/picture/10655" },
    { name: "港开心特码", url: "https://h5.49217008.com:8443/#/picture/10459" },
    { name: "四九开心果", url: "https://h5.49217008.com:8443/#/picture/15900" },
    { name: "香港老人味", url: "https://h5.49217008.com:8443/#/picture/15919" },
    { name: "香港女人味", url: "https://h5.49217008.com:8443/#/picture/15901" },
    { name: "香港男人味", url: "https://h5.49217008.com:8443/#/picture/15863" },
    { name: "新生活幽默", url: "https://h5.49217008.com:8443/#/picture/12933" },
    { name: "马会生活幽", url: "https://h5.49217008.com:8443/#/picture/15823" },
    { name: "香港熊出没1", url: "https://h5.49217008.com:8443/#/picture/15856" },
    { name: "香港熊出没2", url: "https://h5.49217008.com:8443/#/picture/15862" },
    { name: "四不像特别", url: "https://h5.49217008.com:8443/#/picture/12681" },
    { name: "四不像中特", url: "https://h5.49217008.com:8443/#/picture/12890" },
    { name: "四不像玄机", url: "https://h5.49217008.com:8443/#/picture/15850" },
    { name: "港周公解梦", url: "https://h5.49217008.com:8443/#/picture/15828" },
    { name: "港36码特围", url: "https://h5.49217008.com:8443/#/picture/15818" },
    { name: "伯乐相马经", url: "https://h5.49217008.com:8443/#/picture/10862" },
    { name: "马经救世报", url: "https://h5.49217008.com:8443/#/picture/10864" },
    { name: "马经平特图", url: "https://h5.49217008.com:8443/#/picture/10876" },
    { name: "香港马经报", url: "https://h5.49217008.com:8443/#/picture/10547" },
    { name: "另凤凰马经", url: "https://h5.49217008.com:8443/#/picture/10206" },
    { name: "凤凰天机图", url: "https://h5.49217008.com:8443/#/picture/15881" },
    { name: "白小姐内幕A", url: "https://h5.49217008.com:8443/#/picture/10006" },
    { name: "白小姐内幕B", url: "https://h5.49217008.com:8443/#/picture/10007" },
    { name: "白小姐救世A", url: "https://h5.49217008.com:8443/#/picture/10493" },
    { name: "白小姐救世B", url: "https://h5.49217008.com:8443/#/picture/10494" },
    { name: "大陆通天报", url: "https://h5.49217008.com:8443/#/picture/10379" },
    { name: "香港大陆报", url: "https://h5.49217008.com:8443/#/picture/10361" },
    { name: "香港大陆仔", url: "https://h5.49217008.com:8443/#/picture/10362" },
    { name: "台湾凤姐报", url: "https://h5.49217008.com:8443/#/picture/10912" },
    { name: "管家婆财经", url: "https://h5.49217008.com:8443/#/picture/10130" },
    { name: "管家婆传密", url: "https://h5.49217008.com:8443/#/picture/10855" },
    { name: "马会财经A", url: "https://h5.49217008.com:8443/#/picture/10039" },
    { name: "马会财经B", url: "https://h5.49217008.com:8443/#/picture/10040" },
    { name: "红姐内幕肖", url: "https://h5.49217008.com:8443/#/picture/10555" },
    { name: "另话中有意", url: "https://h5.49217008.com:8443/#/picture/10844" },
    { name: "正话中有意", url: "https://h5.49217008.com:8443/#/picture/10556" },
    { name: "九龙内幕A", url: "https://h5.49217008.com:8443/#/picture/10304" },
    { name: "九龙内幕B", url: "https://h5.49217008.com:8443/#/picture/10305" },
    { name: "九龙救世报", url: "https://h5.49217008.com:8443/#/picture/10119" },
    { name: "九龙内幕报", url: "https://h5.49217008.com:8443/#/picture/10606" },
    { name: "天线宝宝A", url: "https://h5.49217008.com:8443/#/picture/10510" },
    { name: "天线宝宝B", url: "https://h5.49217008.com:8443/#/picture/10511" },
    { name: "天线宝宝C", url: "https://h5.49217008.com:8443/#/picture/10512" },
    { name: "另版通天报", url: "https://h5.49217008.com:8443/#/picture/10848" },
    { name: "内部传真报", url: "https://h5.49217008.com:8443/#/picture/10913" },
    { name: "香港牛头报", url: "https://h5.49217008.com:8443/#/picture/10281" },
    { name: "港新抓码王", url: "https://h5.49217008.com:8443/#/picture/10244" },
    { name: "正版抓码王", url: "https://h5.49217008.com:8443/#/picture/10516" },
    { name: "港趣味幽默", url: "https://h5.49217008.com:8443/#/picture/15833" },
    { name: "六合宝典A", url: "https://h5.49217008.com:8443/#/picture/10306" },
    { name: "六合宝典B", url: "https://h5.49217008.com:8443/#/picture/10309" },
    { name: "千手观音", url: "https://h5.49217008.com:8443/#/picture/10088" },
    { name: "神算策略", url: "https://h5.49217008.com:8443/#/picture/10578" },
    { name: "姜子牙神算A", url: "https://h5.49217008.com:8443/#/picture/10314" },
    { name: "姜子牙神算B", url: "https://h5.49217008.com:8443/#/picture/10315" },
    { name: "六合神童A", url: "https://h5.49217008.com:8443/#/picture/10049" },
    { name: "六合神童B", url: "https://h5.49217008.com:8443/#/picture/10050" },
    { name: "神童透码报", url: "https://h5.49217008.com:8443/#/picture/11016" },
    { name: "正版通天报", url: "https://h5.49217008.com:8443/#/picture/10652" },
    { name: "救世通天报", url: "https://h5.49217008.com:8443/#/picture/10648" },
    { name: "数理通天报", url: "https://h5.49217008.com:8443/#/picture/10663" },
    { name: "香港猪哥报", url: "https://h5.49217008.com:8443/#/picture/10243" },
    { name: "无敌猪哥报", url: "https://h5.49217008.com:8443/#/picture/10506" },
    { name: "平特王日报", url: "https://h5.49217008.com:8443/#/picture/10880" },
    { name: "小鱼儿30码", url: "https://h5.49217008.com:8443/#/picture/15899" },
    { name: "平特乾坤卦", url: "https://h5.49217008.com:8443/#/picture/10881" },
    { name: "平特心水报", url: "https://h5.49217008.com:8443/#/picture/10878" },
    { name: "宝宝平特图", url: "https://h5.49217008.com:8443/#/picture/10431" },
    { name: "平特内幕精选", url: "https://h5.49217008.com:8443/#/picture/10879" },
    { name: "金钥匙平特报", url: "https://h5.49217008.com:8443/#/picture/10804" },
    { name: "香港爆码料A", url: "https://h5.49217008.com:8443/#/picture/10693" },
    { name: "香港爆码料B", url: "https://h5.49217008.com:8443/#/picture/10703" },
    { name: "帮您翻本A", url: "https://h5.49217008.com:8443/#/picture/10324" },
    { name: "帮您翻本B", url: "https://h5.49217008.com:8443/#/picture/10325" },
    { name: "香港豹女郎", url: "https://h5.49217008.com:8443/#/picture/11005" },
    { name: "港六合码讯", url: "https://h5.49217008.com:8443/#/picture/10188" },
    { name: "波叔一波", url: "https://h5.49217008.com:8443/#/picture/10220" },
    { name: "牛派波色报", url: "https://h5.49217008.com:8443/#/picture/10677" },
    { name: "挂牌宝典", url: "https://h5.49217008.com:8443/#/picture/10117" },
    { name: "香港博彩通B", url: "https://h5.49217008.com:8443/#/picture/10434" },
    { name: "香港博彩通A", url: "https://h5.49217008.com:8443/#/picture/10433" },
    { name: "香港包租婆", url: "https://h5.49217008.com:8443/#/picture/10581" },
    { name: "香港百蛇图", url: "https://h5.49217008.com:8443/#/picture/10528" },
    { name: "博彩神算王", url: "https://h5.49217008.com:8443/#/picture/10432" },
    { name: "百家乐玄机", url: "https://h5.49217008.com:8443/#/picture/10808" },
    { name: "港宝宝平特", url: "https://h5.49217008.com:8443/#/picture/10431" },
    { name: "精选八肖图", url: "https://h5.49217008.com:8443/#/picture/10689" },
    { name: "港不服来战", url: "https://h5.49217008.com:8443/#/picture/12901" },
    { name: "港老鼠精A", url: "https://h5.49217008.com:8443/#/picture/15954" },
    { name: "港老鼠精B", url: "https://h5.49217008.com:8443/#/picture/15955" },
    { name: "精装彩霸王", url: "https://h5.49217008.com:8443/#/picture/10110" },
    { name: "港东方心经A", url: "https://h5.49217008.com:8443/#/picture/10170" },
    { name: "港东方心经B", url: "https://h5.49217008.com:8443/#/picture/10171" },
    { name: "心经加大版A", url: "https://h5.49217008.com:8443/#/picture/10816" },
    { name: "心经加大版B", url: "https://h5.49217008.com:8443/#/picture/10817" },
    { name: "新东方心经", url: "https://h5.49217008.com:8443/#/picture/12908" },
    { name: "港挂牌寻宝", url: "https://h5.49217008.com:8443/#/picture/10871" },
    { name: "美女六肖图", url: "https://h5.49217008.com:8443/#/picture/10858" },
    { name: "六肖中特图", url: "https://h5.49217008.com:8443/#/picture/10698" },
    { name: "麒麟救世报", url: "https://h5.49217008.com:8443/#/picture/11029" },
    { name: "正版2800信封", url: "https://h5.49217008.com:8443/#/picture/10118" },
    { name: "广州传真猜特", url: "https://h5.49217008.com:8443/#/picture/10615" },
    { name: "广州传真中特", url: "https://h5.49217008.com:8443/#/picture/10616" },
    { name: "广州传真猜特2", url: "https://h5.49217008.com:8443/#/picture/15908" },
    { name: "香港花仙子", url: "https://h5.49217008.com:8443/#/picture/15845" },
    { name: "黄大仙灵码A", url: "https://h5.49217008.com:8443/#/picture/10027" },
    { name: "黄大仙灵码B", url: "https://h5.49217008.com:8443/#/picture/10028" },
    { name: "刘半仙哑谜", url: "https://h5.49217008.com:8443/#/picture/10080" },
    { name: "佛祖送灵码", url: "https://h5.49217008.com:8443/#/picture/12931" },
    { name: "香港频果报", url: "https://h5.49217008.com:8443/#/picture/10589" },
    { name: "神医药方", url: "https://h5.49217008.com:8443/#/picture/10590" },
    { name: "相入非非", url: "https://h5.49217008.com:8443/#/picture/15821" }
]

export const tuzhitaiwan = [
    { name: "点击查看更多图纸　", url: "https://j.manolotron.com:49/" },
    { name: "正版通天报社", url: "https://h5.49217008.com:8443/#/picture/316451" },
    { name: "八仙过海", url: "https://h5.49217008.com:8443/#/picture/316468" },
    { name: "八仙指路", url: "https://h5.49217008.com:8443/#/picture/316481" },
    { name: "七星彩", url: "https://h5.49217008.com:8443/#/picture/316484" },
    { name: "\\t内幕二波十码", url: "https://h5.49217008.com:8443/#/picture/316485" },
    { name: "\\t凤凰天机", url: "https://h5.49217008.com:8443/#/picture/316491" },
    { name: "功夫早茶", url: "https://h5.49217008.com:8443/#/picture/316452" },
    { name: "关公砍三刀", url: "https://h5.49217008.com:8443/#/picture/316492" },
    { name: "鬼谷子", url: "https://h5.49217008.com:8443/#/picture/316455" },
    { name: "黄财神报", url: "https://h5.49217008.com:8443/#/picture/316443" },
    { name: "金财神报", url: "https://h5.49217008.com:8443/#/picture/316441" },
    { name: "金钱豹真功夫", url: "https://h5.49217008.com:8443/#/picture/316459" },
    { name: "今日闲情", url: "https://h5.49217008.com:8443/#/picture/316480" },
    { name: "今日闲情2", url: "https://h5.49217008.com:8443/#/picture/316471" },
    { name: "台湾精选平特", url: "https://h5.49217008.com:8443/#/picture/316457" },
    { name: "看图解特码", url: "https://h5.49217008.com:8443/#/picture/316442" },
    { name: "开心果", url: "https://h5.49217008.com:8443/#/picture/316437" },
    { name: "另版跑狗", url: "https://h5.49217008.com:8443/#/picture/316440" },
    { name: "蓝财神报", url: "https://h5.49217008.com:8443/#/picture/316479" },
    { name: "老版跑狗", url: "https://h5.49217008.com:8443/#/picture/316458" },
    { name: "台湾欲钱买", url: "https://h5.49217008.com:8443/#/picture/316462" },
    { name: "毛哥心水", url: "https://h5.49217008.com:8443/#/picture/316460" },
    { name: "每日闲情", url: "https://h5.49217008.com:8443/#/picture/316447" },
    { name: "美女六肖图", url: "https://h5.49217008.com:8443/#/picture/316439" },
    { name: "平财神报", url: "https://h5.49217008.com:8443/#/picture/316486" },
    { name: "平特播报员", url: "https://h5.49217008.com:8443/#/picture/316446" },
    { name: "台湾平特一肖", url: "https://h5.49217008.com:8443/#/picture/316493" },
    { name: "台湾青龙报", url: "https://h5.49217008.com:8443/#/picture/316489" },
    { name: "麒麟大屠杀", url: "https://h5.49217008.com:8443/#/picture/316444" },
    { name: "趣味幽默", url: "https://h5.49217008.com:8443/#/picture/316475" },
    { name: "四不像玄机图", url: "https://h5.49217008.com:8443/#/picture/316469" },
    { name: "四组三连肖", url: "https://h5.49217008.com:8443/#/picture/316478" },
    { name: "铁板神算", url: "https://h5.49217008.com:8443/#/picture/316435" },
    { name: "两肖中特", url: "https://h5.49217008.com:8443/#/picture/316456" },
    { name: "台湾三头六码", url: "https://h5.49217008.com:8443/#/picture/316461" },
    { name: "必杀令", url: "https://h5.49217008.com:8443/#/picture/316498" },
    { name: "单双宝典", url: "https://h5.49217008.com:8443/#/picture/316463" },
    { name: "必中单双", url: "https://h5.49217008.com:8443/#/picture/316473" },
    { name: "台湾挂牌", url: "https://h5.49217008.com:8443/#/picture/316449" },
    { name: "精准四肖八码", url: "https://h5.49217008.com:8443/#/picture/316476" },
    { name: "台湾看图抓码", url: "https://h5.49217008.com:8443/#/picture/316482" },
    { name: "看图找生肖", url: "https://h5.49217008.com:8443/#/picture/316466" },
    { name: "台湾跑狗图", url: "https://h5.49217008.com:8443/#/picture/316497" },
    { name: "猛虎报", url: "https://h5.49217008.com:8443/#/picture/316477" },
    { name: "台湾内幕传真", url: "https://h5.49217008.com:8443/#/picture/316495" },
    { name: "台湾跑马图", url: "https://h5.49217008.com:8443/#/picture/316445" },
    { name: "平特图", url: "https://h5.49217008.com:8443/#/picture/316487" },
    { name: "正版平特一肖图", url: "https://h5.49217008.com:8443/#/picture/316453" },
    { name: "四不像中特图", url: "https://h5.49217008.com:8443/#/picture/316454" },
    { name: "正版西游报", url: "https://h5.49217008.com:8443/#/picture/316465" },
    { name: "台湾小纸条", url: "https://h5.49217008.com:8443/#/picture/316438" },
    { name: "台湾正版射牌", url: "https://h5.49217008.com:8443/#/picture/316494" },
    { name: "台湾老师", url: "https://h5.49217008.com:8443/#/picture/316450" },
    { name: "熊出没2", url: "https://h5.49217008.com:8443/#/picture/316467" },
    { name: "相入非非", url: "https://h5.49217008.com:8443/#/picture/316483" },
    { name: "小鱼儿心水报", url: "https://h5.49217008.com:8443/#/picture/316470" },
    { name: "银财神", url: "https://h5.49217008.com:8443/#/picture/316464" },
    { name: "幽默猜测", url: "https://h5.49217008.com:8443/#/picture/316448" },
    { name: "一字解特码", url: "https://h5.49217008.com:8443/#/picture/316436" },
    { name: "正版孩童图", url: "https://h5.49217008.com:8443/#/picture/316488" },
    { name: "蛇蛋图", url: "https://h5.49217008.com:8443/#/picture/316472" },
    { name: "紫财神报", url: "https://h5.49217008.com:8443/#/picture/316490" },
    { name: "周公玄机报", url: "https://h5.49217008.com:8443/#/picture/316474" },
    { name: "庄家吃码图", url: "https://h5.49217008.com:8443/#/picture/316496" },
    { name: "30码中特", url: "https://h5.49217008.com:8443/#/picture/316511" },
    { name: "台湾火麒麟", url: "https://h5.49217008.com:8443/#/picture/316506" },
    { name: "独家原创12码", url: "https://h5.49217008.com:8443/#/picture/316501" },
    { name: "佛祖禁肖图", url: "https://h5.49217008.com:8443/#/picture/316513" },
    { name: "金多宝传真", url: "https://h5.49217008.com:8443/#/picture/316500" },
    { name: "绿财神报", url: "https://h5.49217008.com:8443/#/picture/316507" },
    { name: "青财神报", url: "https://h5.49217008.com:8443/#/picture/316499" },
    { name: "石狮镇码", url: "https://h5.49217008.com:8443/#/picture/316514" },
    { name: "台湾客家娘", url: "https://h5.49217008.com:8443/#/picture/316512" },
    { name: "台湾传真", url: "https://h5.49217008.com:8443/#/picture/316509" },
    { name: "特码王", url: "https://h5.49217008.com:8443/#/picture/316502" },
    { name: "特码王2", url: "https://h5.49217008.com:8443/#/picture/316508" },
    { name: "特码王3", url: "https://h5.49217008.com:8443/#/picture/316510" },
    { name: "特码王4", url: "https://h5.49217008.com:8443/#/picture/316505" },
    { name: "台湾彩票中心", url: "https://h5.49217008.com:8443/#/picture/316504" },
    { name: "值日生肖", url: "https://h5.49217008.com:8443/#/picture/316503" },
    { name: "更多彩图", url: "https://j.manolotron.com:49/" }
]

export const tuzhixincai = [
    { name: "点击查看更多图纸", url: "https://www.0065tk.com/" },
    { name: "新加坡四不像", url: "https://0065tk.com/pic.html#col/19377" },
    { name: "新加坡红虎三肖", url: "https://0065tk.com/pic.html#col/19373" },
    { name: "管家婆一句话", url: "https://0065tk.com/pic.html#col/19222" },
    { name: "新加坡金多宝", url: "https://0065tk.com/pic.html#col/19331" },
    { name: "\\t今日闲情二", url: "https://0065tk.com/pic.html#col/19279" },
    { name: "\\t新加坡传真", url: "https://0065tk.com/pic.html#col/19241" },
    { name: "美人鱼单双", url: "https://0065tk.com/pic.html#col/19344" },
    { name: "新加坡每日闲情", url: "https://0065tk.com/pic.html#col/19285" },
    { name: "平特一肖图", url: "https://0065tk.com/pic.html#col/19228" },
    { name: "新加坡想入非非", url: "https://0065tk.com/pic.html#col/19267" },
    { name: "新加坡功夫早茶", url: "https://0065tk.com/pic.html#col/19298" },
    { name: "新加坡青龙报", url: "https://0065tk.com/pic.html#col/19376" },
    { name: "新加坡火凤凰", url: "https://0065tk.com/pic.html#col/19354" },
    { name: "新加坡银财神", url: "https://0065tk.com/pic.html#col/19268" },
    { name: "新加坡一句真言", url: "https://0065tk.com/pic.html#col/19318" },
    { name: "新加坡跑狗图", url: "https://0065tk.com/pic.html#col/19375" },
    { name: "新加坡另版跑狗", url: "https://0065tk.com/pic.html#col/19282" },
    { name: "新加坡老版跑狗", url: "https://0065tk.com/pic.html#col/19283" },
    { name: "新加坡看图抓码", url: "https://0065tk.com/pic.html#col/19357" },
    { name: "新加坡捉生肖", url: "https://0065tk.com/pic.html#col/19363" },
    { name: "新加坡看图解特", url: "https://0065tk.com/pic.html#col/19280" },
    { name: "新加坡通天解码", url: "https://0065tk.com/pic.html#col/19224" },
    { name: "新加坡欲钱料", url: "https://0065tk.com/pic.html#col/19245" },
    { name: "四不像玄机图", url: "https://0065tk.com/pic.html#col/19288" },
    { name: "新加坡传真三肖", url: "https://0065tk.com/pic.html#col/19243" },
    { name: "新加坡特码王A", url: "https://0065tk.com/pic.html#col/19314" },
    { name: "新加坡老人味", url: "https://0065tk.com/pic.html#col/19225" },
    { name: "新加坡女人味", url: "https://0065tk.com/pic.html#col/19226" },
    { name: "新加坡2组3连", url: "https://0065tk.com/pic.html#col/19220" },
    { name: "新加坡平特", url: "https://0065tk.com/pic.html#col/19227" },
    { name: "新加坡蛇蛋图", url: "https://0065tk.com/pic.html#col/19271" },
    { name: "新加坡单双图", url: "https://0065tk.com/pic.html#col/19221" },
    { name: "独平1肖1码", url: "https://0065tk.com/pic.html#col/19229" },
    { name: "新加坡幽默猜测", url: "https://0065tk.com/pic.html#col/19269" },
    { name: "新加坡孩童报", url: "https://0065tk.com/pic.html#col/19223" },
    { name: "新加坡狼女侠客", url: "https://0065tk.com/pic.html#col/19237" },
    { name: "佛祖禁肖图", url: "https://0065tk.com/pic.html#col/19252" },
    { name: "新加坡男人味", url: "https://0065tk.com/pic.html#col/19238" },
    { name: "挂牌平特报", url: "https://0065tk.com/pic.html#col/19253" },
    { name: "小黄人幽默", url: "https://0065tk.com/pic.html#col/19254" },
    { name: "济公七句真言", url: "https://0065tk.com/pic.html#col/19255" },
    { name: "新加坡玄机妙语", url: "https://0065tk.com/pic.html#col/19256" },
    { name: "新加坡小幽默图", url: "https://0065tk.com/pic.html#col/19270" },
    { name: "新加坡玄机图", url: "https://0065tk.com/pic.html#col/19257" },
    { name: "新加坡紫财神报", url: "https://0065tk.com/pic.html#col/19272" },
    { name: "新加坡铁板神算", url: "https://0065tk.com/pic.html#col/19264" },
    { name: "新加坡周公玄机", url: "https://0065tk.com/pic.html#col/19273" },
    { name: "新加坡蓝色欲钱", url: "https://0065tk.com/pic.html#col/19284" },
    { name: "新加坡熊出没2", url: "https://0065tk.com/pic.html#col/19266" },
    { name: "新加坡鱼跃龙门", url: "https://0065tk.com/pic.html#col/19258" },
    { name: "新加坡开心果", url: "https://0065tk.com/pic.html#col/19281" },
    { name: "新加坡平财神报", url: "https://0065tk.com/pic.html#col/19286" },
    { name: "新加坡黄财神报", url: "https://0065tk.com/pic.html#col/19299" },
    { name: "新加坡金财神报", url: "https://0065tk.com/pic.html#col/19300" },
    { name: "新加坡绿财神报", url: "https://0065tk.com/pic.html#col/19309" },
    { name: "新加坡红财神报", url: "https://0065tk.com/pic.html#col/19328" },
    { name: "新加坡花仙子", url: "https://0065tk.com/pic.html#col/19329" },
    { name: "新加坡蓝财神报", url: "https://0065tk.com/pic.html#col/19333" },
    { name: "青财神报", url: "https://h5.49217008.com:8443/#/picture/316499" },
    { name: "新加坡七星彩", url: "https://0065tk.com/pic.html#col/19339" },
    { name: "新加坡猛虎报", url: "https://0065tk.com/pic.html#col/19374" },
    { name: "新加坡正版财神", url: "https://0065tk.com/pic.html#col/19372" },
    { name: "新加坡银财神", url: "https://0065tk.com/pic.html#col/19324" },
    { name: "新加坡码头诗", url: "https://0065tk.com/pic.html#col/19311" },
    { name: "新加坡通缉令A", url: "https://0065tk.com/pic.html#col/19296" },
    { name: "新加坡通缉令B", url: "https://0065tk.com/pic.html#col/19297" },
    { name: "新加坡吃码图", url: "https://0065tk.com/pic.html#col/19303" },
    { name: "新加坡惠泽了知", url: "https://0065tk.com/pic.html#col/19330" },
    { name: "广州传真猜特", url: "https://0065tk.com/pic.html#col/19325" },
    { name: "新加坡特码王B", url: "https://0065tk.com/pic.html#col/19315" },
    { name: "新加坡特码王C", url: "https://0065tk.com/pic.html#col/19316" },
    { name: "新加坡公证处2", url: "https://0065tk.com/pic.html#col/19327" },
    { name: "新加坡公证处1", url: "https://0065tk.com/pic.html#col/19326" },
    { name: "脑筋急转弯", url: "https://0065tk.com/pic.html#col/19324" },
    { name: "新加坡鬼谷子", url: "https://0065tk.com/pic.html#col/19341" },
    { name: "特码金手指", url: "https://0065tk.com/pic.html#col/19347" },
    { name: "新加坡无痕跑狗", url: "https://0065tk.com/pic.html#col/19360" },
    { name: "新加坡火麒麟", url: "https://0065tk.com/pic.html#col/19355" },
    { name: "新加坡生活幽默", url: "https://0065tk.com/pic.html#col/19345" },
    { name: "新加坡另版挂牌", url: "https://0065tk.com/pic.html#col/19356" },
    { name: "新加坡36码中特", url: "https://0065tk.com/pic.html#col/19369" },
    { name: "新加坡赢钱秘诀", url: "https://0065tk.com/pic.html#col/19406" },
    { name: "财经快报A", url: "https://0065tk.com/pic.html#col/19403" },
    { name: "新加坡内部特码", url: "https://0065tk.com/pic.html#col/19551" },
    { name: "TOTO黄大仙神码", url: "https://0065tk.com/pic.html#col/19543" },
    { name: "新加坡六合内幕", url: "https://0065tk.com/pic.html#col/19546" },
    { name: "新加坡彩民之家", url: "https://0065tk.com/pic.html#col/19560" },
    { name: "新加坡内部传真", url: "https://0065tk.com/pic.html#col/19547" },
    { name: "正版管家婆", url: "https://0065tk.com/pic.html#col/19470" }
]

// 各大资料网
// 香港
export const zongzhanxianggang = [
    { name: "香港雷锋论坛", url: "https://fggfgfg.www552257a.com:8443/#492121" },
    { name: "香港跑狗论坛", url: "https://gfbbggb.www776627a.com:8443/#492121" },
    { name: "香港六合彩", url: "https://hjyjthjy.www661161c.com:8443/#492121" },
    { name: "香港彩库", url: "https://dwqda.www331162c.com:8443/#492121" },
    { name: "香港四不像", url: "https://loisltrf.www557723c.com:8443/#492121" },
    { name: "香港88开奖", url: "https://dwqvfe.www668821c.com:8443/#492121" },
    { name: "香港123电脑", url: "https://jxcmcc.www551163c.com:8443/#492121" },
    { name: "香港天龙论坛", url: "https://qvtvrh.www558839c.com:8443/#492121" },
    { name: "香港挂牌", url: "https://kha12g.www552278c.com:8443/#492121" },
    { name: "香港王中王", url: "https://adfddadfs.www113382c.com:8443/#492121" },
    { name: "香港蛇蛋图", url: "https://fgfgdfg.www552275c.com:8443/#492121" },
    { name: "香港六合之家", url: "https://kjkmsss.www335535c.com:8443/#492121" },
    { name: "香港马会论坛", url: "https://gfgttyhy.www221127c.com:8443/#492121" },
    { name: "香港博彩网", url: "https://250099.com/#492121" },
    { name: "香港九龙论坛", url: "https://gfbgffg.www776673c.com:8443/#492121" },
    { name: "香港报彩神童", url: "https://jgfgsshf.www997783c.com:8443/#492121" },
    { name: "香港神算子", url: "https://rqcas.www225592c.com:8443/#492121" },
    { name: "香港铁算盘", url: "https://bnfgfgn.www551132c.com:8443/" },
    { name: "香港六合二站", url: "https://192255.com/#492121" },
    { name: "香港118开奖", url: "https://118528.com/#492121" },
    { name: "香港玄机网", url: "https://792266.com/#492121" },
    { name: "香港管家婆", url: "https://614088.com/#492121" },
    { name: "香港马经论坛", url: "https://2ntk8v.suaxzdimctlfw.com:2038/#492121" },
    { name: "香港天天好彩", url: "https://24639.com/#492121" },
    { name: "香港抓码王", url: "https://272733.com/#492121" },
    { name: "香港八仙过海", url: "https://391199.com/#492121" },
    { name: "香港小鱼儿", url: "https://325577.com/#492121" },
    { name: "香港醉八仙", url: "https://510066.com/#492121" },
    { name: "香港广东会", url: "https://593399.com/#492121" },
    { name: "香港东方心经", url: "https://852266.com/#492121" },
    { name: "香港大陆仔", url: "https://890099.com/#492121" },
    { name: "香港姜太公", url: "https://01736.com/#492121" },
    { name: "香港白小姐", url: "https://47457.com/#492121" },
    { name: "香港118论坛", url: "https://118085.com/#492121" },
    { name: "香港黄大仙", url: "https://31087.com/#492121" },
    { name: "香港公式网", url: "https://602019.com/#492121" },
    { name: "香港118手机", url: "https://118978.com/#492121" },
    { name: "香港老奇人", url: "https://775592.com/#492121" },
    { name: "香港49图库", url: "https://49070b.com/#492121" },
    { name: "香港九龙图库", url: "https://902018.com/#492121" },
    { name: "香港118图库", url: "https://118676.com/#492121" },
    { name: "香港六合宝典", url: "https://43563.com/#492121" },
    { name: "香港36计", url: "https://368808.com/#492121" },
    { name: "香港赛马会", url: "https://nulrt1.ympjzxjgnfxyq.com:2036/#492121" },
    { name: "香港123图库", url: "https://123905.com/#492121" },
    { name: "香港今日闲情", url: "https://oplzy5.ltizfoxedybdl.com:2035/#492121" },
    { name: "香港美人鱼", url: "https://123156.com/#492121" },
    { name: "香港123论坛", url: "https://123786.com/#492121" },
    { name: "香港刘伯温", url: "https://5fbgzm.zfaolqdqtrhpt.com:2034/#492121" },
    { name: "香港123手机", url: "https://123570a.com/#492121" },
    { name: "香港彩民网", url: "https://vxsh60.msalvyygmcano.com:2033/#492121" },
    { name: "香港红姐论坛", url: "https://22ndfs.ucwqmdgytqjdq.com:2032/#492121" },
    { name: "香港淘码论坛", url: "https://5zkkvr.vnxffytnagfcy.com:2031/#492121" },
    { name: "香港宝马论坛", url: "https://3utb2m.cevpvvpnldfgu.com:2030/#492121" },
    { name: "香港西游论坛", url: "https://m8lwq4.jqvzqpxdk1840.com:2029/#492121" },
    { name: "香港六合大亨", url: "https://bxqe50.vizjftmxa2037.com:2028/#492121" },
    { name: "香港幽默玄机", url: "https://ulqu1m.jowtpleka1089.com:2027/#492121" },
    { name: "香港百晓生", url: "https://q0bjgy.lynwsocrx8297.com:2026/#492121" },
    { name: "香港六合味", url: "https://ztkus7.grsoyaiehqd510.com:2025/#492121" },
    { name: "香港凤凰天机", url: "https://492121a.com/://e75nxk.etfzsafnrqd002.com:2024/#492121" },
    { name: "香港夜明珠", url: "https://c0mkqx.hmspjgtrjqd220.com:2023/#492121" },
    { name: "香港观音救世", url: "https://i1zbdy.grygwagitqd510.com:2022/#492121" },
    { name: "香港一点红", url: "https://25znyy.ivlpaenzrqd350.com:2021/#492121" },
    { name: "香港J2视频", url: "httsp://bli3wx.jqvzqpxdkqd220.com:2020/#492121" },
    { name: "香港大赢家", url: "https://629797.com/#492121" },
    { name: "香港状元红", url: "https://925151.com/#492121" },
    { name: "香港金光佛", url: "https://305599.com/#492121" },
    { name: "香港六合皇", url: "https://830033.com/#492121" },
    { name: "香港彩霸王", url: "https://298877.com/#492121" },
    { name: "香港01开奖", url: "https://010190.com/#492121" },
    { name: "香港曾夫人", url: "https://302019.com/#492121" },
    { name: "香港红虎", url: "https://952929.com/#492121" },
    { name: "香港天线宝宝", url: "https://291166.com/#492121" },
    { name: "香港青龙阁", url: "https://976767.com/#492121" },
    { name: "香港摇钱树", url: "https://171166.com/#492121" },
    { name: "香港水果奶奶", url: "https://008816.com/#492121" },
    { name: "香港六合慈善", url: "https://607766.com/#492121" },
    { name: "香港惠泽社群", url: "https://956565.com/#492121" },
    { name: "香港鬼谷子", url: "https://157272.com/#492121" },
    { name: "香港六合头条", url: "https://303322.com/#492121" },
    { name: "香港全讯网", url: "https://212133.com/#492121" },
    { name: "香港聚彩堂", url: "https://675151.com/#492121" },
    { name: "香港老鼠报", url: "https://162019.com/#492121" },
    { name: "香港藏宝阁", url: "https://392626.com/#492121" },
    { name: "香港财神报", url: "https://100208.com/#492121" },
    { name: "香港六合一站", url: "https://493388.com/#492121" }
]
// 六合彩
export const zongzhanliuhe = [
    { name: "六合图库", url: "https://www.49853c.com/#492121" },
    { name: "六合马会传真", url: "https://www.576969.com/#492121" },
    { name: "六合四不像", url: "https://www.920011.cc/#492121" },
    { name: "六合跑狗", url: "https://www.335526.com/#492121" },
    { name: "六合王中王", url: "https://72293c.com/#492121" },
    { name: "六合金多宝", url: "https://www.217676.com/#492121" },
    { name: "六合88开奖", url: "https://www.885570.com/#492121" },
    { name: "六合123开奖", url: "https://kjvh1c.www123861a.com:8443/#492121" },
    { name: "六合118开奖", url: "https://www.118186.com/#492121" },
    { name: "六合老奇人", url: "https://www.185599.com/#492121" },
    { name: "六合小鱼儿", url: "https://www.258686.com/#492121" },
    { name: "六合一站", url: "https://www.626969b.com/#492121" },
    { name: "六合二站", url: "https://www.628282.com/#492121" },
    { name: "六合跑马", url: "https://www.216969.com/#492121" },
    { name: "六合玄机", url: "https://www.862727.com/#492121" },
    { name: "六合雷锋", url: "https://www.290123c.com/#492121" },
    { name: "六合博彩", url: "https://www.816969.com/#492121" },
    { name: "六合九龙", url: "https://www.258989.com/#492121" },
    { name: "六合抓码王", url: "https://www.316969.com/#492121" },
    { name: "六合八仙过海", url: "https://www.391155.com/#492121" },
    { name: "六合彩霸王", url: "https://www.835577.com/#492121" },
    { name: "六合醉八仙", url: "https://www.316969.com/#492121" },
    { name: "六合广东会", url: "https://www.391155.com/#492121" },
    { name: "六合东方心经", url: "https://www.497272.com/#492121" },
    { name: "六合创富", url: "https://www.258811.com/#492121" },
    { name: "六合大陆仔", url: "https://www.615511.com/#492121" },
    { name: "六合姜太公", url: "https://www.156565.com/#492121" },
    { name: "六合神算子", url: "https://www.171212.com/#492121" },
    { name: "六合慈善网", url: "https://www.689292a.com/#492121" },
    { name: "六合蛇蛋图", url: "https://www.165252.com/#492121" },
    { name: "六合刘伯温", url: "https://sdfwddg.www58392c.com:888/#492121" },
    { name: "六合黄大仙", url: "https://www.526161.com/#492121" },
    { name: "六合全讯网", url: "https://www.291313.cc/#492121" },
    { name: "六合红虎", url: "https://www.182929.com/#492121" },
    { name: "六合天线宝宝", url: "https://www.989292.com/#492121" },
    { name: "六合铁算盘", url: "https://www.913737.com/#492121" },
    { name: "六合水果奶奶", url: "https://www.628787.com/#492121" },
    { name: "六合彩库网", url: "https://www.353513.com/#492121" },
    { name: "六合藏宝阁", url: "https://www.962626a.com/#492121" },
    { name: "六合青龙阁", url: "https://www.125757a.com/#492121" },
    { name: "六合曾道人", url: "https://www.496161a.com/#492121" },
    { name: "六合摇钱树", url: "https://www.197979.com/#492121" },
    { name: "六合鬼谷子", url: "https://www.197722.com/#492121" },
    { name: "六合报彩神童", url: "https://www.873322.com/#492121" },
    { name: "六合惠泽社群", url: "https://www.687575.com/#492121" },
    { name: "六合六合头条", url: "https://www.383300.com/#492121" },
    { name: "六合聚彩堂", url: "https://www.626161.com/#492121" },
    { name: "六合老鼠报", url: "https://www.507070.com/#492121" },
    { name: "六合彩票网", url: "https://www.23396a.com/#492121" },
    { name: "六合彩民", url: "https://esjos1.www25261a.com:8443/#492121" },
    { name: "六合红姐", url: "https://6u3f27.www26283a.com:8443/#492121" },
    { name: "六合淘码", url: "https://g5hg3h.www27921a.com:8443/#492121" },
    { name: "六合凤凰天机", url: "https://fbd1af.www52832a.com:8443/#492121" },
    { name: "六合宝马", url: "https://51h9te.www31793a.com:8443/#492121" },
    { name: "六合西游降庄", url: "https://8zj9xn.www79591a.com:8443/#492121" },
    { name: "六合今日闲情", url: "https://wf6dph.www15637a.com:8443/#492121" },
    { name: "六合六合大亨", url: "https://h8fk3n.www28673a.com:8443/#492121" },
    { name: "六合幽默玄机", url: "https://3khjtw.www52785a.com:8443/#492121" },
    { name: "六合百晓生", url: "https://n28j9n.www52639a.com:8443/#492121" },
    { name: "六合六合味", url: "https://8ish7w.www52817a.com:8443/#492121" },
    { name: "六合挂牌网", url: "https://fnvon7.www95287a.com:8443/#492121" },
    { name: "六合美人鱼", url: "https://1s6nhv.www28715a.com:8443/#492121" },
    { name: "六合白小姐", url: "https://2qqhmn.www31976a.com:8443/#492121" },
    { name: "六合夜明珠", url: "https://m1scyp.www56521a.com:8443/#492121" },
    { name: "六合管家婆", url: "https://au75qy.www28751a.com:8443/#492121" },
    { name: "六合观音救世", url: "https://7q6m3h.www221185a.com:8443/#492121" },
    { name: "六合一点红", url: "https://fgy3zz.www53183a.com:8443/#492121" },
    { name: "六合论坛", url: "https://www.49706.com/#492121" },
    { name: "六合246资料", url: "https://www.24628.com/#492121" },
    { name: "六合大赢家", url: "https://www.963535.com/#492121" },
    { name: "六合六合宝典", url: "https://www.961515.com/#492121" },
    { name: "六合金财神", url: "https://www.397373.com/#492121" },
    { name: "六合金光佛", url: "https://www.316363.com/#492121" },
    { name: "六合状元红", url: "https://www.272626.com/#492121" },
    { name: "六合六合皇", url: "https://www.492525.com/#492121" },
    { name: "六合六合之家", url: "https://www.912121.com/#492121" },
    { name: "六合视频直播", url: "https://www.118186.com/#492121" },
    { name: "六合金吊桶", url: "https://www.376600.com/#492121" },
    { name: "六合赢彩吧", url: "https://www.493399.com/#492121" },
    { name: "六合彩票通", url: "https://www.595533.com/#492121" },
    { name: "澳赛马会", url: "https://861166.com/#492121" },
    { name: "六合金手指", url: "https://765500.com/#492121" },
    { name: "六合花仙子", url: "https://719911.com/#492121" },
    { name: "六合红双喜", url: "https://876611.com/#492121" },
    { name: "六合天下彩", url: "https://870077.com/#492121" },
    { name: "六合满堂红", url: "https://675511.com/#492121" },
    { name: "六合六合彩", url: "https://626969b.com/#492121" }
]
//台湾
export const zongzhantaiwan = [
    { name: "台湾六合彩", url: "https://616363.com/#492121" },
    { name: "台湾彩券网", url: "https://398686.com/#492121" },
    { name: "台湾彩库网", url: "https://269696.com/#492121" },
    { name: "台湾彩讯网", url: "https://581616.com/#492121" },
    { name: "台彩图库", url: "https://490886.com/#492121" },
    { name: "台湾金多宝", url: "https://498383.com/#492121" },
    { name: "敬请期待", url: "https://616363.com/#492121" },
    { name: "敬请期待", url: "https://269696.com/#492121" }
]

export function generateRandomArray(min, max, length) {
    var randomArray = [];
    // 创建一个包含指定范围内所有可能值的数组
    var allValues = [];
    for (var i = min; i <= max; i++) {
        allValues.push(i);
    }
    // 从 allValues 数组中随机选择元素，并将其加入 randomArray 数组中
    for (var j = 0; j < length; j++) {
        var randomIndex = Math.floor(Math.random() * allValues.length);
        var randomValue = allValues.splice(randomIndex, 1)[0];
        if (randomValue < 10) {
            randomValue = '0' + randomValue
        } else {
            randomValue = '' + randomValue
        }
        randomArray.push(randomValue);
    }

    return randomArray;
}

export function isTimeWithinRange(startTime, endTime) {
    var currentDate = new Date();
    var start = new Date();
    var end = new Date();

    // 将传入的开始时间和结束时间转换为 Date 对象
    var startParts = startTime.split(":");
    start.setHours(parseInt(startParts[0]));
    start.setMinutes(parseInt(startParts[1]));
    start.setSeconds(parseInt(startParts[2]));

    var endParts = endTime.split(":");
    end.setHours(parseInt(endParts[0]));
    end.setMinutes(parseInt(endParts[1]));
    end.setSeconds(parseInt(endParts[2]));

    // 判断当前时间是否在开始时间和结束时间之间
    if (currentDate >= start && currentDate <= end) {
        return true; // 当前时间在传入的时间范围内
    } else {
        return false; // 当前时间不在传入的时间范围内
    }
}

export function checkTimeGreaterThanCurrent(time) {
    var currentTime = new Date();
    var parts = time.split(":");
    var inputTime = new Date();

    // 将传入的时间设置为与当前时间相同的日期
    inputTime.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
    inputTime.setHours(parseInt(parts[0]));
    inputTime.setMinutes(parseInt(parts[1]));
    inputTime.setSeconds(parseInt(parts[2]));

    // 判断传入时间是否大于当前时间
    if (inputTime > currentTime) {
        return true; // 传入时间大于当前时间
    } else {
        return false; // 传入时间不大于当前时间
    }
}

var timer
export function listenTime(targetTime, callback) {
    if (timer) clearInterval(timer)
    timer = setInterval(function () {
        var currentTime = new Date();
        // 获取当前时间的小时、分钟和秒
        var currentHours = currentTime.getHours();
        var currentMinutes = currentTime.getMinutes();
        var currentSeconds = currentTime.getSeconds();
        // 将目标时间字符串分割为小时、分钟和秒
        var targetParts = targetTime.split(":");
        var targetHours = parseInt(targetParts[0]);
        var targetMinutes = parseInt(targetParts[1]);
        var targetSeconds = parseInt(targetParts[2]);
        // 判断当前时间是否等于指定时间（精确到秒）
        if (
            currentHours === targetHours &&
            currentMinutes === targetMinutes &&
            currentSeconds === targetSeconds
        ) {
            callback(); // 当前时间等于指定时间，执行回调函数
        }
    }, 1000); // 每秒钟执行一次
}
