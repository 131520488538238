<template>
  <div class="tukedaohang">
    <div class="nav">
      <div
        class="item"
        :class="{ active: active == index }"
        v-for="(item, index) in nav"
        :key="index"
        @click="active = index"
      >
        {{ item }}
      </div>
    </div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <a :href="item.url" target="_blank">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>
  
  <script setup>
import { computed, ref } from "vue";
import { tuzhiaomen, tuzhixianggang, tuzhitaiwan, tuzhixincai } from "@/utils";
const active = ref(0);
const nav = ref(["澳门图库", "香港图库", "台湾图库", "新彩图库"]);
const list = computed(() => {
  if (active.value == 0) {
    return tuzhiaomen;
  } else if (active.value == 1) {
    return tuzhixianggang;
  } else if (active.value == 2) {
    return tuzhitaiwan;
  } else if (active.value == 3) {
    return tuzhixincai;
  }
});
</script>
  
  <style lang="scss">
.tukedaohang {
  border: 3px solid #3ebc40;
  .nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #3ebc40;
    .item {
      flex: 1;
      padding: 8px 0;
      text-align: center;
      color: #47a5ff;
      font-weight: bold;
      &:first-child {
        color: #ff59ff;
      }
      &:last-child {
        color: #ff4b4b;
      }
    }
    .active {
      background-color: #3ebc40;
      color: #fff !important;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    li {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 12px 3px;
      text-align: center;
      background-color: #ffff00;
      a {
        color: red;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
</style>