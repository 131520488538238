<template>
  <div class="plan">
    <sanxiao :data="data.sanXiao" v-if="data?.sanXiao" />
    <dujia :data="data.dujiachang" v-if="data?.dujiachang" />
    <liuxiao :data="data.qiXiao" v-if="data?.qiXiao" />
    <tou :data="data.tou" v-if="data?.tou" />
    <bose :data="data.boSe" v-if="data?.boSe" />
    <code24 :data="data.code24" v-if="data?.code24" />
    <wei :data="data.wei" v-if="data?.wei" />
    <chunxiaqiudong :data="data.cxqd" v-if="data?.cxqd" />
    <wuXingZhongTe :data="data.wuXingZhongTe" v-if="data?.wuXingZhongTe" />
    <qianhou :data="data.qianHou" v-if="data?.qianHou" />
    <danshuang :data="data.danShuang" v-if="data?.danShuang" />
    <yixiao :data="data.yiXiao" v-if="data?.yiXiao" />
    <daXiao :data="data.daXiaoShu" v-if="data?.daXiaoShu" />
    <!--  -->
    <danshuang4xiao :data="data.danshuang4xiao" v-if="data?.danshuang4xiao" />
    <heshudanshuang :data="data.heshudanshuang" v-if="data?.heshudanshuang" />
    <pingtesanxiao :data="data.pingtesanxiao" v-if="data?.pingtesanxiao" />
    <jingzhun20ma :data="data.jingzhun20ma" v-if="data?.jingzhun20ma" />
    <wenzhuan2bo :data="data.wenzhuan2bo" v-if="data?.wenzhuan2bo" />
    <neimuertou :data="data.neimu2tou" v-if="data?.neimu2tou" />
    <biaozhun7wei :data="data.biaozhun7wei" v-if="data?.biaozhun7wei" />
    <wensha2xiao :data="data.wensha2xiao" v-if="data?.wensha2xiao" />
    <sixiaobama
      :data="data.miezhuangsixiaobama"
      v-if="data?.miezhuangsixiaobama"
    />
    <jiaqingyeshou :data="data.jiaqingyeshou" v-if="data?.jiaqingyeshou" />
    <daxiaozhongte :data="data.daxiaozhongte" v-if="data?.daxiaozhongte" />
    <bizhongdanshuang
      :data="data.bizhongdanshuang"
      v-if="data?.bizhongdanshuang"
    />
  </div>
</template>

<script setup>
import { myHttp } from "@/request/index.js";
import { ref } from "vue";
import liuxiao from "./liuxiao.vue";
import bose from "./bose.vue";
import danshuang from "./danshuang.vue";
import tou from "./tou.vue";
import wei from "./wei.vue";
import yixiao from "./yixiao.vue";
import code24 from "./code24.vue";
import sanxiao from "./sanxiao.vue";
import daXiao from "./daXiao.vue";
import wuXingZhongTe from "./wuXingZhongTe.vue";
import chunxiaqiudong from "./chunxiaqiudong.vue";
import qianhou from "./qianhou.vue";
import dujia from "./dujia.vue";
import bizhongdanshuang from "./bizhongdanshuang.vue";
import danshuang4xiao from "./danshuang4xiao.vue";
import daxiaozhongte from "./daxiaozhongte.vue";
import heshudanshuang from "./heshudanshuang.vue";
import jiaqingyeshou from "./jiaqingyeshou.vue";
import jingzhun20ma from "./jingzhun20ma.vue";
import sixiaobama from "./sixiaobama.vue";
import neimuertou from "./neimuertou.vue";
import pingtesanxiao from "./pingtesanxiao.vue";
import wensha2xiao from "./wensha2xiao.vue";
import wenzhuan2bo from "./wenzhuan2bo.vue";
import biaozhun7wei from "./biaozhun7wei.vue";

const data = ref(null);

function loadData() {
  myHttp.get("/api/information/queryAll_2").then((res) => {
    if (res.success) {
      data.value = res.data;
      console.log({ ...data.value });
    }
  });
}

loadData();
</script>

<style lang="scss" scoped>
::v-deep .plan_item {
  .plan_item_title {
    // background-color: #3ebc40 !important;
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  ul {
    li {
      // background: linear-gradient(to bottom, #e893f9, #ab79e4) !important;
      padding: 12px 0;
      font-weight: 600;
    }
  }
}
.plan {
  .title {
    text-align: center;
    // background-color: #009788;
    font-size: 25px;
    font-weight: bold;
    padding: 6px 0;
  }
  .plan_item {
    border: 1px solid #009788;
    .plan_item_advertisement {
      img {
        width: 100%;
        height: 40px;
        display: block;
      }
    }
    .plan_item_title {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      font-weight: bold;
      text-align: center;
      // background-image: linear-gradient(to bottom, #e893f9, #ab79e4) !important;
      padding: 3px 0;
      border-top: 2px solid #767474;
      border-bottom: 2px solid #767474;
      img {
        width: 30px;
        height: 30px;
      }
    }
    ul {
      background-color: #eee;
      li {
        text-align: center;
        padding: 5px 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        .value {
          color: red;
          .win {
            background-color: #ffff00;
          }
        }
        &:not(:last-child) {
          border-bottom: 2px solid #d2d2d2;
        }
      }
      .li_win {
        .winText {
          color: #d00880;
        }
      }
      .suck {
        text-decoration-line: line-through;
      }
    }
  }

  .TK118 {
    width: 100%;
    height: 70px;
  }
}
</style>